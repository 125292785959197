import axios from "axios";
import { baseUrl } from "./config";
import { encodeBody, encodeQueryParams } from "./client";

export const getProjectsByClubId: any = (params:any) => {
    const path = `${baseUrl}/api/projects${encodeQueryParams(params)}`;
    return axios({
      method: 'GET',
      url: path,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    }).then(response => response.data.data);
  };

  export const getProjectDetails: any = (params:any) => {
    const path = `${baseUrl}/api/projects/${params.projectId}/details${encodeQueryParams({
      pastStatusPeriod: params.pastStatusPeriod,
    })}`;
    return axios({
      method: 'GET',
      url: path,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    }).then(response => response.data.data);
  };
  
export const editProject: any = (params:any) => {
  const path = `${baseUrl}/api/projects/${params.projectId}`;
  return axios({
    method: 'PUT',
    url: path,
    data: encodeBody(params.project),
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  }).then(response => response.data.data);
};

export const addProject: any = (params:any) => {
  const path = `${baseUrl}/api/projects`;
  return axios({
    method: 'POST',
    url: path,
    data: encodeBody(params.project),
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  }).then(response => response.data.data);
};

export const deleteProject: any = (params:any) => {
  const path = `${baseUrl}/api/projects/${params.projectId}`;
  return axios({
    method: 'DELETE',
    url: path,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  }).then(response => response.data.data);
};

export const archiveProject: any = (params:any) => {
  const path = `${baseUrl}/api/projects/archive/${params.projectId}`;
  return axios({
    method: 'POST',
    url: path,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  }).then(response => response.data.data);
};

export const unassignAction: any = (params:any) => {
  const path = `${baseUrl}/api/projects/${params.projectId}/actions/${params.actionId}`;

  return axios({
    method: 'DELETE',
    url: path,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  }).then(response => response.data.data);
};