import React, { useState, useRef } from 'react';
import './styles.css';
import ClubStandards from '../../containers/ClubStandards';
import { Box } from '@mui/material';
import { CommentsOverviewBox } from '../../containers/CommentsOverviewBox';
import { ReportComment } from '../../containers/ReportComment';

const ResizableComponent = (props:any) => {
    const { captureData } = props;
    const [dragging, setDragging] = useState(false);
    const [topHeight, setTopHeight] = useState('auto'); // Default height to fit content
    const containerRef:any = useRef(null);
    const initialMouseY = useRef(0);
    const initialTopHeight = useRef(0);

    const handleMouseDown = (e:any) => {
        setDragging(true);
        initialMouseY.current = e.clientY;
        initialTopHeight.current = containerRef.current
        .querySelector('.top')
        .getBoundingClientRect().height;
    };

    const handleMouseMove = (e:any) => {
        if (dragging && containerRef.current) {
        const containerHeight = containerRef.current.offsetHeight;
        const deltaY = e.clientY - initialMouseY.current;
        const newTopHeight = initialTopHeight.current + deltaY;
        const clampedTopHeight:any = Math.min(Math.max(newTopHeight, 0), containerHeight);

        setTopHeight(clampedTopHeight);
        }
    };

    const handleMouseUp = () => {
        setDragging(false);
    };

  return (
    <div
      className="resizable-container"
      ref={containerRef}
      onMouseMove={handleMouseMove}
      onMouseUp={handleMouseUp}
      onMouseLeave={handleMouseUp}
    >
      <div className="top" style={{ height: topHeight }}>
        <ClubStandards />
      </div>
      <div
        className="separator"
        onMouseDown={handleMouseDown}
      />
      <div className="bottom">
        <Box sx={{ width: '30%' }}>
            <CommentsOverviewBox manageClick={captureData} parentComponent={'jlEval'} />
        </Box>
        <Box sx={{ width: '70%' }}>
            <ReportComment />
        </Box>
      </div>
    </div>
  );
};

export default ResizableComponent;
