import React from "react";
import "./styles.css";
//external or internal compoents
import SearchBox from "../../components/SearchBox";
import FilterBox from "../../components/FilterBox";

const SearchFilterContainer = (props:any) => {
    const { parent } = props;
    return(
        <React.Fragment>
            <div className={parent == 'Performance Plan & Strategy' ? "visiblityHidden" : "SearchFilterWrapper"}>
                <SearchBox searchBoxStyle = {'searchBoxMain'} parent={parent ? parent : ''}/>
                {
                    parent == 'adminHome' &&
                    <FilterBox />
                }
            </div>
        </React.Fragment>
    )
}

export default SearchFilterContainer;