import React, { useEffect, useState } from "react";
import {
  Box,
  InputAdornment,
  InputLabel,
  ThemeProvider,
  createTheme,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { colors } from "../../common/pallette/colors";

const DateSelectorDefault = (props: any) => {
  const { variant, label, dateValue, manageChange, textColor } = props;
  const [value, setValue] = useState(dateValue);
  const [isOpen, setIsOpen] = useState(false);

  const theme = createTheme({
    components: {
      MuiInput: {
        styleOverrides: {
          input: {
            height: "auto",
            fontSize: "13px",
            color: textColor,
          },
          root: {
            "&::before": {
              borderBottom: "none",
            },
            "&::after": {
              borderBottom: "none",
            },
            "&:hover:not(.Mui-disabled, .Mui-error)::before": {
              borderBottom: "none",
            },
          },
        },
      },
      MuiInputBase: {
        styleOverrides: {
          root: {
            borderBottom: "none",
            "&:hover:not(.Mui-disabled, .Mui-error)::before": {
              borderBottom: "none",
            },
          },
        },
      },
      MuiStack: {
        styleOverrides: {
          root: {
            paddingTop: "0px !important",
          },
        },
      },
    },
  });

  useEffect(() => {
    if(value == null || value == undefined || value == '') {
      setValue(dateValue);
    }
  }, [dateValue])

  return (
    <ThemeProvider theme={theme}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Box sx={{ width: "100%" }}>
          {(label && label !== "") && (
            <InputLabel className="selectBoxLabel">{label}</InputLabel>
          )}
          <DatePicker
            open={isOpen}
            onChange={(v) => {
              if (!v || v.isValid()) {
                setValue(v ? v.format("YYYY-MM-DD") : null);
                manageChange(v ? v.format("YYYY-MM-DD") : null);
              }
            }}
            value={value ? dayjs(value) : null}
            format="YYYY-MM-DD"
            onOpen={() => setIsOpen(true)}
            onClose={() => setIsOpen(false)}
            slots={{
                openPickerIcon: () => (
                    <>
                      {isOpen ? (
                        <FontAwesomeIcon
                          color={colors.black.dark}
                          width={"12px"}
                          icon={faChevronUp}
                        />
                      ) : (
                        <FontAwesomeIcon
                          color={colors.black.dark}
                          width={"12px"}
                          icon={faChevronDown}
                        />
                      )}
                    </>
                  ),
            }}
            slotProps={{
              textField: {
                variant: variant ? variant : "outlined",
                size: "small",
                fullWidth: true,
                onKeyDown: (e) => {
                  if (e.key === "Backspace") {
                    setValue(null);
                    e.preventDefault();
                  }
                },
                onClick: () => {setIsOpen(!isOpen)}
              },
            }}
          />
        </Box>
      </LocalizationProvider>
    </ThemeProvider>
  );
};

export default DateSelectorDefault;
