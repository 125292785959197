import * as React from 'react';
import { useSelector, useDispatch } from "react-redux";
import '../styles.css';
//internal and external components import
import { Button, Table, Tooltip } from '@mui/material';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import StatusIndicatorSmall from '../../StatusIndicatorSmall';
import ProgressBar from '../../ProgressBar';
import { stableSort, descendingComparator, getComparator, sortData } from '../../../common/SortSupport';
import { EnhancedTableHead } from '../../EnhancedTableHead';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faCirclePlus, faMaximize, faTrash } from '@fortawesome/free-solid-svg-icons';
import { Box } from '@mui/material';
import { CustomTooltip } from '../../CustomTooltip';
import { colors } from '../../../common/pallette/colors';
import TableSkeleton from '../../TableSkeleton';
import { resetSaStandards, resetSelectedSaCriteria, setSelectedSaCriteria } from '../../../redux/reducers/evaluation';
import { formatDate, genUID } from '../../../common/StaticExports';
import CommentsDocsActionsIconWrapper from '../../CommentsDocsActionsIconWrapper';
import NewMarker from '../../NewMarker';
import { useTranslation } from 'react-i18next';
import { resetSelectedProject, setProjects, setSelectedProject } from '../../../redux/reducers/actionPlan';
import ButtonDefault from '../../ButtonDefault';
import NewProjectDetailPopper from '../../../containers/NewProjectDetailPopper';
import NestedThreeDotMenu from '../../NestedThreeDotMenu';
import { faEyeSlash } from '@fortawesome/free-regular-svg-icons';
import { archiveProject, deleteProject, getProjectsByClubId } from '../../../api/projects';


export default function ProjectsTable(props: any) {

  const dispatch = useDispatch();
  const clubDetails = useSelector((state:any) => state.clubDetails);
  const [t] = useTranslation();
  const [addNewProject, setAddNewProject] = React.useState(false);
  const data = useSelector((state:any) => state.actionPlan.projects);
  const selectedProject = useSelector((state:any) => state.actionPlan.selectedProject);
  const [isLoadingProjects, setIsLoadingProjects] = React.useState(false);

  //supporting contents
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('id');

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: any,
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
    
  };

  const visibleRows:any = React.useMemo(
    () =>
      data?.length > 0 &&
      sortData(data, orderBy, order),
    [order, orderBy, data],
  );

  const head = [
    {
      key: '', displayName: 'id',
    },
    {
      key: 'name', displayName: 'project',
    },
    {
      key: '', displayName: '',
    },
    {
      key: 'ragb', displayName: 'status',
    },
    {
      key: 'ownerName', displayName: 'owner',
    },
    {
      key: 'departmentName', displayName: 'department',
    },
    {
      key: 'dueDate', displayName: 'due date',
    },
    {
      key: 'progress', displayName: 'progress',
    },
    {
      key: '', displayName: '',
    }
  ];

  const threeDotMenuOptions =
  [
    {label: 'Archive Project', icon: faEyeSlash, isDisabled: false},
    {label: 'Delete Project', icon: faTrash, isDisabled: false},
  ];
  const handleThreedotMenuClick = async (selctedAction:any, project:any) => {
    switch(selctedAction) {
      case 'Delete Project':
        await deleteProject({ projectId: project?.id });
        fetchProjects();
        break;
      case 'Archive Project':
        await archiveProject({ projectId: project?.id });
        fetchProjects();
        break;
    }
  }

  const fetchProjects = async () => {
    setIsLoadingProjects(true);
    const response = await getProjectsByClubId({ archivedStatus: 1, clubId: clubDetails?.id, pastStatusPeriod: 1 });
    dispatch(setProjects(response));
    setIsLoadingProjects(false);
  };

  React.useEffect(() => {
    clubDetails && clubDetails?.id && data &&
    fetchProjects();
  }, [clubDetails]);

  const manageTableExpand = () => {
    dispatch(resetSelectedProject());
  }

  const manageTableShrink = (d: any, index:any) => {
    dispatch(setSelectedProject({...d, projectIndex: index+1}));
  }  

  //table contents 
  return (
    <React.Fragment>
      <Box sx={{ height: '100%', position: 'relative' }}>
        <NewProjectDetailPopper isOpen={addNewProject} handleClickAway={() => {setAddNewProject(false); fetchProjects();}} />
        {!(Object.keys(selectedProject).length === 0) && <FontAwesomeIcon className='icon tableExpandIcon' icon={faMaximize} onClick={() => manageTableExpand()} />}
        <TableContainer sx={{height: '100%', width: !(Object.keys(selectedProject).length === 0) ? 'fit-content' : 'auto', overflow: Object.keys(selectedProject).length === 0 ? 'auto' : 'hidden'}}>
          <Table stickyHeader sx={{ width: Object.keys(selectedProject).length === 0 ? '100%' : 'auto', boxShadow: 'none' }} size="small" aria-label="a dense table">
            <EnhancedTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              bg={'#fff'}
              tableHeads={Object.keys(selectedProject).length === 0 ? head : [head[0]]}
            />
            <TableBody>
              {
                (!isLoadingProjects && visibleRows?.length) ?
                visibleRows.map((d: any, i: number) => (
                  <TableRow
                    className='hoverBG'
                    key={`projects-dynamic-table-row-${i}`}
                  >
                    <CustomTooltip title={d.name} className={!(Object.keys(selectedProject).length === 0) ? 'capitalize' : 'displayNone'} arrow followCursor placement='top-start'>
                      <TableCell className={(!(Object.keys(selectedProject).length === 0) && selectedProject.id === d.id) ? 'fw900 ' : ''}
                    onClick={() => manageTableShrink(d, i)}>{i+1}</TableCell>
                    </CustomTooltip>
                    {
                      (Object.keys(selectedProject).length === 0) && <>
                      <TableCell className='capitalize' sx={{cursor: 'pointer', width: '500px'}}
                    onClick={() => manageTableShrink(d, i)}>
                        {d.name}
                      </TableCell>
                      <TableCell className='fs12' onClick={() => manageTableShrink(d, i)}><CommentsDocsActionsIconWrapper actions={d?.noOfActions ? d?.noOfActions : 0}/></TableCell>
                      <TableCell onClick={() => manageTableShrink(d, i)}><StatusIndicatorSmall reportType={'rabg'} status={d.ragb} /></TableCell>
                      <TableCell onClick={() => manageTableShrink(d, i)}>
                        {d?.ownerName ? d?.ownerName : '-'}
                      </TableCell>
                      <TableCell onClick={() => manageTableShrink(d, i)}>
                        {d?.departmentName ? d?.departmentName : '-'}
                      </TableCell>
                      <TableCell onClick={() => manageTableShrink(d, i)}>
                        {d?.dueDate ? formatDate(d?.dueDate) : '-'}
                      </TableCell>
                      <TableCell onClick={() => manageTableShrink(d, i)}>
                        <ProgressBar percent={d?.progress}/>
                      </TableCell>
                      <TableCell>
                        <NestedThreeDotMenu 
                        options={threeDotMenuOptions} 
                        manageClick={(e:any) => {handleThreedotMenuClick(e, d)}} />
                      </TableCell>
                      </>
                    } 
                  </TableRow>
                ))
                :
                <TableSkeleton rowCount={13} cellCount={8} />
              }
            </TableBody>
          </Table>
        </TableContainer>
        {
          (Object.keys(selectedProject).length === 0) &&
          <Box sx={{ float: 'left', mt: 2 }}>
            <ButtonDefault
            variant={'outlined'}
            value={t('ADD_PROJECT')}
            icon={<FontAwesomeIcon icon={faCirclePlus} />}
            manageOnClick={() => {setAddNewProject(true)}}
            disabled={addNewProject}
            />
          </Box>
        }
      </Box>
    </React.Fragment>
  );
}