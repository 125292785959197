import React, { startTransition, useEffect } from "react";
import "./styles.css";
//internal and external components/containers import
import SearchFilterContainer from "../../containers/SearchFilterContainer";
import { Box, Tabs, Tab, Typography, } from "@mui/material";
import ClubCriteria from "../../containers/ClubCriteria";
import FinalReportOverview from "../../containers/PhaseHeader";
import ReportNavigator from "../../components/ReportNavigator";
import EvaluationTabs from "../../containers/EvaluationTabs";
import { useLocation } from "react-router";
import { useDispatch } from "react-redux";
import { setCurrentEvaluationTab } from "../../redux/reducers/evaluation";
import { useTranslation } from "react-i18next";


interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
  }
  
function TabPanel(props: TabPanelProps) {
const { children, value, index, ...other } = props;

return (
    <div
    role="tabpanel"
    hidden={value !== index}
    id={`simple-tabpanel-${index}`}
    className={"simpleTabPanel"}
    aria-labelledby={`simple-tab-${index}`}
    {...other}
    >
    {value === index && (
        <Box sx={{ p: 2 }}>
        <Typography>{children}</Typography>
        </Box>
    )}
    </div>
);
}

function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

const ClubsHomePage = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const [t] = useTranslation();

    // const { tabLabels } = props;
    const [value1, setValue1] = React.useState(0);
    const [value2, setValue2] = React.useState(6);

    const handleChange1 = (event: React.SyntheticEvent, newValue: number) => {
      setValue1(newValue);
    };

    const handleChange2 = (event: React.SyntheticEvent, newValue: number) => {
        setValue2(newValue);
      };

      useEffect(() => {
        
      console.log(location);
      }, [location]);
    startTransition(() => {      
      var tab:any = "";
      switch(location.pathname) {
        case '/selfassessment':
          tab = t("EVALUATION");
          dispatch(setCurrentEvaluationTab(tab));
          break;
        case '/onsite':
          tab = t("ON_SITE");
          dispatch(setCurrentEvaluationTab(tab));
          break;
        case '/reports':
          tab = t("REPORTS");
          dispatch(setCurrentEvaluationTab(tab));
          break;
      } 
    });

    return(
        <React.Fragment>
            <Box className={'contentSpacing'}>
                <Box className='headerWrapper'>
                  <SearchFilterContainer parent={'evaluation'} />
                </Box>
                <EvaluationTabs />
            </Box>
        </React.Fragment>
    )
}

export default ClubsHomePage;