import React, { useEffect, useState } from "react";
import TableSingleSelectBox from "../SearchSelectBox/TableSingleSelectBox";
import { useSelector } from "react-redux";

const OnSiteIntervieweeSelectBox = (props:any) => {
    const { value, manageSelect, selectedOptions } = props;
    const clubUsers = useSelector((state:any) => state.common.clubUsers);
    const [inputValue, setInputValue] = React.useState(value ? (value?.name || '') : '');
    const [optionList, setOptionList] = useState(clubUsers || []);

    useEffect(() => {
      const ids = selectedOptions.map((d:any) => d.userId);
      const tempArr = optionList.filter((item:any) => !ids.includes(item.id));
      setOptionList(tempArr);
    }, [value, manageSelect]);

    const manageHandleChange = (_event: any, newValue: any) => {
      let tempObj:any = Object; 
      
      if (newValue == null || newValue == undefined) {
        tempObj = {...value, name: null, userId: null, role: null};
      } else if(newValue && newValue?.name && newValue.name.startsWith('Create "')) {
        tempObj = {...value, name: newValue.name.slice(8, -1), userId: newValue.id};
      } else {
        tempObj = {...value, name: newValue.name, userId: newValue.id};
      }

      manageSelect(tempObj);
    };
  
    const manageHandleInputChange = (_event: any, newInputValue: string) => {
      (newInputValue != undefined || newInputValue != 'undefined') &&
      setInputValue(newInputValue);
  
      const filteredOptions = optionList.filter((option: any) => !option.displayName?.startsWith('Create'));
  
      if (newInputValue && !filteredOptions.some((option: any) => option.displayName === newInputValue || option.name === newInputValue)) {
        setOptionList([
          ...filteredOptions,
          { displayName: `Create "${newInputValue}"` }
        ]);
      } else {
        setOptionList(filteredOptions);
      }
    };

    return (
        <TableSingleSelectBox 
        options={optionList.map((item:any) => {return {...item, name: item.displayName}})}
        keyLabel={'name'}
        handleChange={manageHandleChange}
        variant={'standard'}
        selectedValue={value}
        handleInputChange={manageHandleInputChange}
        inputValue={inputValue}
        />
    )
}

export default OnSiteIntervieweeSelectBox