import { Box } from "@mui/material";
import React from "react";
import { commonStatus, finalReportStatus, interimReportStatus, liecenceAdmin, liecenceHome, rabgStatus, siteReviewStatus } from '../../common/StaticExports';
import { useTranslation } from "react-i18next";
import { colors } from "../../common/pallette/colors";

const StatusIndicator= (props:any) => {
    const {
        status,
        reportType,
     } = props;

     const [t] = useTranslation();

     const displayStatus = (key: String, statusI: number) => {
        switch (key) {
            case 'interim':
                return interimReportStatus[statusI];
    
            case 'final':
                return finalReportStatus[statusI];
                
            case 'rabg':
                return rabgStatus[statusI];
            
            case 'siteReview':
                return siteReviewStatus[statusI];
    
            case 'liecenceHome':
                return liecenceHome[statusI];
                
            case 'liecenceAdminAction':
                return liecenceAdmin[statusI];
        
            default:
                return commonStatus[statusI];
        }
    }

    return (
        <Box
        sx={{ 
            bgcolor: displayStatus(reportType, status)?.color,
            color: colors.white.bright,
            fontWeight: 700, 
            borderRadius: '12px', 
            fontSize: '12px',
            height: '18px',
            p: '6px' }}>
            {t(displayStatus(reportType, status).statusName)}
        </Box>
    );
}

export default StatusIndicator;