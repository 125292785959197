import { Box, ClickAwayListener, Fade, Paper, Popper, Typography } from "@mui/material";
import React, { useState } from "react";
import "./styles.css";
import StatusIndicator from "../../components/StatusIndicator";
import StatusSelectBoxDefault from "../../components/StatusSelectBoxDefault";
import OwnerSelectBox from "../../components/OwnerSelectBox";
import DepartmentSelectBox from "../../components/DepartmentSelectBox";
import PerfActionDateSelector from "../../components/PerfActionDateSelector";
import { t } from "i18next";
import TasksTable from "../../components/TasksTable";
import PerfActionStandardsTable from "../../components/PerfActionStandardsTable";
import { CommentsOverviewBox } from "../CommentsOverviewBox";
import { ReportComment } from "../ReportComment";
import TextFieldDefault from "../../components/TextFieldDefault";
import StatusSelectBoxPNAD from "../../components/StatusSelectBoxPNAD";
import TextAreaDefault from "../../components/TextAreaDefault";
import PerformanceActionsTable from "../../components/PerformanceActionsTable";
import { TextAreaPerformanceAction } from "../../components/TextAreaPerformanceAction";
import { TextFieldProject } from "../../components/TextFieldProject";
import { addProject } from "../../api/projects";

const NewProjectDetailPopper = (props:any) => {
    const { isOpen, handleClickAway } = props;
    const [newProjectData, setNewProjectData]:any = useState({});
    
    const handleClickInsidePopper = (event: MouseEvent) => {
        event.stopPropagation();
    }

    const addNewProject = async () => {
        const response = await addProject({ project: {...newProjectData, actions: []} });
    }

    //handling other changes
    const handleChangeState = (updatedData:any) => {
        var tempObj = { ...newProjectData, ...updatedData };
        setNewProjectData(tempObj);
    }

    const manageClickAway = () => {
        Object.keys(newProjectData).length > 0 &&
        Object.values(newProjectData).some(value => value !== null && value !== '') &&
        addNewProject();
        setNewProjectData({});
        handleClickAway();
    }

    return (
        <React.Fragment>
            <Popper className='popperStyle' open={isOpen} transition>
                {({ TransitionProps }) => (
                    <ClickAwayListener onClickAway={manageClickAway}>
                        <Fade {...TransitionProps} timeout={350}>
                            <Paper sx={{ p: 2, width: '1200px', height: '90vh', overflowY: 'auto', margin: 'auto', mt: '4vh' }} onClick={() => handleClickInsidePopper}>
                                <Box className={'NPDmainWrapper'}>
                                    <Typography className="fs14 fw700 maxWidth70p uppercase">{t('NEW_PROJECT')}</Typography>
                                    <Box className={'mt30'}>
                                        <TextFieldProject 
                                        label={t('PROJECT_NAME')}
                                        value={newProjectData?.name}
                                        handleChange={handleChangeState} />
                                    </Box>
                                    <Box className={'mt30'}>
                                        <TextAreaPerformanceAction 
                                        label={t('PROJECT_DESCRIPTION')}
                                        value={newProjectData?.description}
                                        handleChange={handleChangeState}
                                        />
                                    </Box>
                                    <Box className={'NPDselectBoxWrapper'}>
                                        <StatusSelectBoxPNAD value={newProjectData?.status} manageSelect={handleChangeState} />
                                        <OwnerSelectBox value={newProjectData?.ownerUserId} manageSelect={handleChangeState} />
                                        <DepartmentSelectBox value={newProjectData?.departmentId} manageSelect={handleChangeState} />
                                        <PerfActionDateSelector value={newProjectData?.dueDate} manageSelect={handleChangeState} />
                                    </Box>
                                    <Box className={'mt30'}>
                                        <Typography className="fs13 fw700 uppercase">{t('ACTIONS')}</Typography>
                                        <PerformanceActionsTable data={newProjectData?.actions ? newProjectData?.actions : []} />
                                    </Box>
                                    <Box className={'mt30'}>
                                        <Typography className="fs13 fw700 uppercase">{t('COMMENTS')}</Typography>
                                        <Box sx={{ display: "flex" }}>
                                            <Box sx={{ width: '30%' }}>
                                                <CommentsOverviewBox manageClick={() => {}} parentComponent={'jlEval'} />
                                            </Box>
                                            <Box sx={{ width: '70%' }}>
                                                <ReportComment />
                                            </Box>
                                        </Box>
                                    </Box>
                                </Box>
                            </Paper>
                        </Fade>
                    </ClickAwayListener>)}
            </Popper>
        </React.Fragment>
    )
}

export default NewProjectDetailPopper;