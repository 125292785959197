import { Box, ClickAwayListener, Fade, Paper, Popper, Typography } from "@mui/material";
import React, { useState } from "react";
import "./styles.css";
import StatusIndicator from "../../components/StatusIndicator";
import StatusSelectBoxDefault from "../../components/StatusSelectBoxDefault";
import OwnerSelectBox from "../../components/OwnerSelectBox";
import DepartmentSelectBox from "../../components/DepartmentSelectBox";
import PerfActionDateSelector from "../../components/PerfActionDateSelector";
import { t } from "i18next";
import TasksTable from "../../components/TasksTable";
import PerfActionStandardsTable from "../../components/PerfActionStandardsTable";
import { CommentsOverviewBox } from "../CommentsOverviewBox";
import { ReportComment } from "../ReportComment";
import TextFieldDefault from "../../components/TextFieldDefault";
import StatusSelectBoxPNAD from "../../components/StatusSelectBoxPNAD";
import TextAreaDefault from "../../components/TextAreaDefault";
import PerfActionLicenceStandardsTable from "../../components/PerfActionLicenceStandardsTable";
import { TextAreaPerformanceAction } from "../../components/TextAreaPerformanceAction";
import SingleSelectDefault from "../../components/SingleSelectDefault";
import { ActionSelcetBoxDefault } from "../../components/ActionSelectBoxDefault";
import { createAction, getAction } from "../../api/action";
import { editProject } from "../../api/projects";
import { useDispatch, useSelector } from "react-redux";
import { setAllActions, setIsActions, setSelectedProject } from "../../redux/reducers/actionPlan";
import { ActionTextBoxDefault } from "../../components/ActionTextBoxDefault";

const PerformanceNewActionDetailPopper = (props:any) => {
    const { isOpen, handleClickAway } = props;
    const [newActionData, setNewActionData]:any = useState({
        "name": "",
        "standards": [],
        "licenceStandards": [],
        "status": 4,
        "objectives": [],
        "tasks": []
    });
    const [isUpdatedData, setIsUpdatedData] = useState(false);
    const selectedProject = useSelector((state:any) => state.actionPlan.selectedProject);
    const dispatch = useDispatch();
    const isActions = useSelector((state:any) => state.actionPlan.isActions);
    const allActions = useSelector((state:any) => state.actionPlan.allActions);
    
    const handleClickInsidePopper = (event: MouseEvent) => {
        event.stopPropagation();
    }

    //handling state updates
    //handling tasks
    const handleChangeTask = (label:any, updatedValue:any, index:any) => {
        var tempTasks = newActionData?.tasks ? newActionData?.tasks : [];
        switch(label) {
            case 'addTask':
                tempTasks = [...tempTasks, updatedValue];
                break;
            case 'deleteTask': 
                const newTasks = tempTasks.filter((_:any, i:any) => i !== index);
                tempTasks = newTasks;
                break;
            default:
                tempTasks = tempTasks.map((item:any, i:any) => {
                    if (i == index) {
                        return { ...item, [label]: updatedValue };
                    } return item;
                });
                break;
        }
        const tempAction = {...newActionData, tasks: tempTasks};
        setIsUpdatedData(true);
        setNewActionData(tempAction);
    };
    //handling standards
    const handleChangeStandards = (label:any, updatedValue:any, index:any) => {
        var tempStandards = newActionData?.standards ? newActionData?.standards : [];
        switch(label) {
            case 'addStandard':
                tempStandards = [...tempStandards, updatedValue];
                break;
            case 'unlinkStandard':
                const newTasks = tempStandards.filter((_:any, i:any) => i !== index);
                tempStandards = newTasks;
                break;
            case 'updateStandard':
                tempStandards = tempStandards.map((item:any, i:any) => {
                    if (i == index) {
                        return updatedValue;
                    } return item;
                });
                break;
        }
        const tempAction = {...newActionData, standards: tempStandards};
        setIsUpdatedData(true);
        setNewActionData(tempAction);
    };
    //handling licence standards
    const handleChangeLicenceStandards = (label:any, updatedValue:any, index:any) => {
        var tempStandards = newActionData?.licenceStandards ? newActionData?.licenceStandards : [];
        switch(label) {
            case 'addStandard':
                tempStandards = [...tempStandards, updatedValue];
                break;
            case 'unlinkStandard':
                const newTasks = tempStandards.filter((_:any, i:any) => i !== index);
                tempStandards = newTasks;
                break;
            case 'updateStandard':
                tempStandards = tempStandards.map((item:any, i:any) => {
                    if (i == index) {
                        return updatedValue;
                    } return item;
                });
                break;
        }
        const tempAction = {...newActionData, licenceStandards: tempStandards};
        setIsUpdatedData(true);
        setNewActionData(tempAction);
    };
    //handling other changes
    const handleChangeState = (updatedData:any) => {
        var tempObj = { ...newActionData, ...updatedData };
        setIsUpdatedData(true);
        setNewActionData(tempObj);
    }
    //handle action Select
    // const handleActionSelect = async (actionData:any) => {
    //     if(actionData && actionData.id) {
    //         const response = await getAction({id: actionData.id, pastStatusPeriod: 1});
    //         setNewActionData(response);
    //     } else {
    //         var tempObj = { ...newActionData, name: actionData.name };
    //         setNewActionData(tempObj);
    //     }
    // }
    //update project api
    const updateproject = async () => {
        const response = await editProject({ projectId: selectedProject?.id, project: {...selectedProject, actions: [newActionData]} });
        const tempObj = { ...selectedProject, actions: response.action };
        dispatch(setSelectedProject(tempObj));
    }

    const manageOnClickAway = async () => {
        if(isActions) {
            if (isUpdatedData) {
                const response = await createAction({action: newActionData});
                const tempActionArr:any = [...allActions, response];
                dispatch(setAllActions(tempActionArr));
            }
        } else {
            selectedProject && selectedProject?.id &&
            Object.keys(newActionData).length > 0 &&
            Object.values(newActionData).some(value => value !== null && value !== '') &&
            updateproject();   
        }
        setNewActionData({});
        handleClickAway();
    }

    return (
        <React.Fragment>
            <Popper className='popperStyle' open={isOpen} transition>
                {({ TransitionProps }) => (
                    <ClickAwayListener onClickAway={manageOnClickAway}>
                        <Fade {...TransitionProps} timeout={350}>
                            <Paper sx={{ p: 2, width: '1200px', height: '90vh', overflowY: 'auto', margin: 'auto', mt: '4vh' }} onClick={() => handleClickInsidePopper}>
                                <Box className={'PNADmainWrapper'}>
                                    <Typography className="fs14 fw700 maxWidth70p">{t('NEW_ACTION')}</Typography>
                                    <Box className={'mt30'}>
                                        <Box sx={{ width: '200px', display: "inline-block" }}>
                                            <TextFieldDefault label={t('ACTION_ID')} />
                                        </Box>
                                        <Box sx={{ width: "970px", ml:"20px", display: "inline-block" }} onClick={() => handleClickInsidePopper}>
                                            <ActionTextBoxDefault 
                                            label={t('ACTION_TITLE')}
                                            value={newActionData?.name ? newActionData?.name : ''} 
                                            handleChange={handleChangeState}/>
                                        </Box>
                                        <Box className={'mt30'}>
                                            <TextAreaPerformanceAction
                                            value={newActionData?.description}
                                            label={t('ACTION_DESCRIPTION')} 
                                            handleChange={handleChangeState}
                                            />
                                        </Box>
                                    </Box>
                                    <Box className={'PNADselectBoxWrapper'}>
                                        <StatusSelectBoxPNAD value={newActionData?.status} manageSelect={handleChangeState} />
                                        <OwnerSelectBox value={newActionData?.ownerUserId} manageSelect={handleChangeState} />
                                        <DepartmentSelectBox value={newActionData?.departmentId} manageSelect={handleChangeState} />
                                        <PerfActionDateSelector value={newActionData?.dueDate} manageSelect={handleChangeState} />
                                    </Box>
                                    <Box className={'mt30'}>
                                        <Typography className="fs13 fw700 uppercase">{t('TASKS')}</Typography>
                                        <TasksTable data={newActionData?.tasks ? newActionData?.tasks : []} isLoading={false} handleChange={handleChangeTask} />
                                    </Box>
                                    <Box className={'mt30'}>
                                        <Typography className="fs13 fw700 uppercase">{t('EVALUATION_STANDARDS')}</Typography>
                                        <PerfActionStandardsTable buttonLabel={t('LINK_EVALUATION_STANDARDS')} 
                                        data={newActionData?.standards ? newActionData?.standards : []} 
                                        manageLinkStandard={handleChangeStandards}
                                        />
                                    </Box>
                                    <Box className={'mt30'}>
                                        <Typography className="fs13 fw700 uppercase">{t('LICENCE_STANDARDS')}</Typography>
                                        <PerfActionLicenceStandardsTable buttonLabel={t('LINK_LICENCE_STANDARDS')} 
                                        data={newActionData?.licenceStandards ? newActionData?.licenceStandards : []} 
                                        manageLinkStandard={handleChangeLicenceStandards}/>
                                    </Box>
                                    <Box className={'mt30'}>
                                        <Typography className="fs13 fw700 uppercase">{t('COMMENTS')}</Typography>
                                        <Box sx={{ display: "flex" }}>
                                            <Box sx={{ width: '30%' }}>
                                                <CommentsOverviewBox manageClick={() => {}} parentComponent={'jlEval'} />
                                            </Box>
                                            <Box sx={{ width: '70%' }}>
                                                <ReportComment />
                                            </Box>
                                        </Box>
                                    </Box>
                                </Box>
                            </Paper>
                        </Fade>
                    </ClickAwayListener>)}
            </Popper>
        </React.Fragment>
    )
}

export default PerformanceNewActionDetailPopper;