import { Button, createTheme, ThemeProvider } from "@mui/material";
import React from "react";
import { colors } from "../../common/pallette/colors";

const ButtonDefault = (props:any) => {
    const {value, variant, icon, manageOnClick, disabled} = props;
    const theme = createTheme({
      components: {
          MuiButton: {
            styleOverrides: {
              root: {
                color: colors.black.dark,
                border: `2px solid ${colors.black.dark}`,
                borderRadius: '12px',
                fontSize: '12px'
              }
            }
          },
      }
    });

    return (
        <ThemeProvider theme={theme}>
            <Button
            variant={variant}
            disableElevation
            disableRipple
            size='small'
            disabled={disabled}
            startIcon={icon ? icon : ''}
            onClick={manageOnClick}
            >{value}</Button>
        </ThemeProvider>
    )
}

export default ButtonDefault;