import React from "react";
//internal and external components and containers import
import { Box, Stack, Switch, Typography, Button } from '@mui/material';
import { styled } from '@mui/material/styles';
import { colors } from "../../common/pallette/colors";


const AntSwitch = styled(Switch)(({ theme }) => ({
    width: 28,
    height: 16,
    padding: 0,
    display: 'flex',
    '&:active': {
      '& .MuiSwitch-thumb': {
        width: 15,
      },
      '& .MuiSwitch-switchBase.Mui-checked': {
        transform: 'translateX(9px)',
      },
    },
    '& .MuiSwitch-switchBase': {
      padding: 2,
      '&.Mui-checked': {
        transform: 'translateX(12px)',
        color: '#fff',
        '& + .MuiSwitch-track': {
          opacity: 1,
          backgroundColor: theme.palette.mode === 'dark' ? '#177ddc' : '#1890ff',
        },
      },
    },
    '& .MuiSwitch-thumb': {
      boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
      width: 12,
      height: 12,
      borderRadius: 6,
      transition: theme.transitions.create(['width'], {
        duration: 200,
      }),
    },
    '& .MuiSwitch-track': {
      borderRadius: 16 / 2,
      opacity: 1,
      backgroundColor:
        theme.palette.mode === 'dark' ? 'rgba(255,255,255,.35)' : 'rgba(0,0,0,.25)',
      boxSizing: 'border-box',
    },
  }));

export const SwitchButton = (props: {label: string, isOn: boolean, handleOnClick: any, inlineStyle?: any, label01?: string}) => {

    const { label, isOn, handleOnClick, inlineStyle, label01 } = props;

    return(
        <React.Fragment>
            <Stack direction="row" spacing={1} alignItems="center">
                <Typography className={label01 ? 'fs12 fw700' : 'fs12'} color={(!isOn && label01) ? colors.black.dark : colors.grey.dark} sx={ inlineStyle }>{label}</Typography>
                <AntSwitch checked={isOn} onClick={handleOnClick} inputProps={{ 'aria-label': 'ant design' }} />
                {label01 && <Typography className={label01 ? 'fs12 fw700' : 'fs12'} color={(isOn && label01) ? colors.black.dark : colors.grey.dark} sx={ inlineStyle }>{label01}</Typography>}
            </Stack>
        </React.Fragment>
    )
}