import React from "react";
import TextAreaDefault from "../TextAreaDefault";

export const TextAreaPerformanceAction = (props:any) => {
    const { value, label, handleChange } = props;
    const manageHandleChange = (newValue:any) => {
        const tempObj = { description: newValue };
        handleChange(tempObj);
    }
    return (
        <React.Fragment>
            <TextAreaDefault 
            value={value}
            label={label}
            handleChange={(e:any) => {manageHandleChange(e.target.value)}}
             />
        </React.Fragment>
    )
}