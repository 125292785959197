import { createSlice } from "@reduxjs/toolkit";
import { sortData } from "../../common/SortSupport";

let initialData: any = {
    searchString: '',
    criteriaGroups: [],
    currentSection: '',
    saCriteria: [],
    selectedSaCriteria: {},
    saStandards: [],
    saActions: [],
    saEvidences: [],
    saComments: {},
    offSiteCriteria: [],
    selectedOffSiteCriteria: {},
    offSiteStandards: [],
    onSiteCriteria: [],
    onSiteDateGroups: [],
    selectedOnSiteCriteria: {},
    selectedOnSiteSession: {},
    onSiteStandards: [],
    evalReportCriteria: [],
    selectedEvalReportCriteria: {},
    evalReportStandards: [],
    actionsList: [],
    staffList: [],
    projectList: [],
    departmentList: [],
    newSaAction: {},
    evaluationTabs: [
        'EVALUATION',
        'ON_SITE',
        'REPORTS'
    ],
    currentEvaluationTab: 'EVALUATION',
    saCriteriaActions: [],
    saCriteriaEvidences: [],
    saCriteriaComments: [],
    offSiteCriteriaComments: [],
    onSiteCriteriaComments: [],
    evalCriteriaComments: [],
    commentContent: null,
    criteriaComment: {
        selfAssessmentComment: '',
        offSiteComment: '',
        onSiteComment: '',
        interimReportComment: '',
        finalReportComment: '',
    },
    selectedCommentPhase: 'OFF_SITE',
    commentPhases: ['OFF_SITE', 'ON_SITE', 'INTERIM_REPORT', 'FINAL_REPORT', 'SELF_ASSESSMENT'],
    selectedChatPhase: 'SELF_ASSESSMENT',
    selectedStandardsChat: {},
    interviewDetails: [],
    interviewers: [],
    interviewTeams: [],
    interviewView: 'LIST',
}

export const evaluation = createSlice({
    name: 'evaluation',
    initialState: initialData,
    reducers: {
        setEvaluationSearchString: (state: any, action: any) => {
            state.searchString =  action.payload;
            return state;
        },
        setCriteriaGroups: (state: any, action: any) => {
            state.criteriaGroups =  action.payload;
            return state;
        },
        updateCurrentSection: (state: any, action: any) => {
            state.currentSection =  action.payload;
            return state;
        },
        setInitialSection: (state: any) => {
            state.currentSection = initialData.currentSection;
            return state;
        },
        setSaCriteria: (state: any, action: any) => {
            var tempArr = action.payload;
            if(tempArr.length > 0) {
                tempArr = sortData(action.payload, 'uid', 'asc')
            }
            state.saCriteria = tempArr;
            return state;
        },
        setSaStandards: (state: any, action: any) => {
            var tempArr = action.payload;
            if(tempArr.length > 0) {
                tempArr = sortData(action.payload, 'uid', 'asc')
            }
            state.saStandards = tempArr;
            return state;
        },
        resetSaStandards: (state: any) => {
            state.saStandards = initialData.saStandards;
            return state;
        },
        setSelectedSaCriteria: (state: any, action: any) => {
            state.selectedSaCriteria = action.payload;
            return state;
        },
        resetSelectedSaCriteria: (state: any) => {
            state.selectedSaCriteria = initialData.selectedSaCriteria;
            return state;
        },
        setSaActions: (state: any, action: any) => {
            state.saActions = action.payload;
            return state;
        },
        resetSaActions: (state: any) => {
            state.saActions = initialData.saActions;
            return state;
        },
        setSaEvidences: (state: any, action: any) => {
            state.saEvidences = action.payload;
            return state;
        },
        resetSaEvidences: (state: any) => {
            state.saEvidences = initialData.saEvidences;
            return state;
        },
        setSaComments: (state: any, action: any) => {
            state.saComments = action.payload;
            return state;
        },
        resetSaComments: (state: any) => {
            state.saEvidences = initialData.saEvidences;
            return state;
        },
        setOffSiteCriteria: (state: any, action: any) => {
            state.offSiteCriteria =  action.payload;
            return state;
        },
        setOffSiteStandards: (state: any, action: any) => {
            state.offSiteStandards = action.payload;
            return state;
        },
        resetOffSiteStandards: (state: any) => {
            state.offSiteStandards = initialData.offSiteStandards;
            return state;
        },
        setSelectedOffSiteCriteria: (state: any, action: any) => {
            state.selectedOffSiteCriteria = action.payload;
            return state;
        },
        resetSelectedOffSiteCriteria: (state: any) => {
            state.selectedOffSiteCriteria = initialData.selectedOffSiteCriteria;
            return state;
        },
        setOnSiteCriteria: (state: any, action: any) => {
            state.onSiteCriteria =  action.payload;
            return state;
        },
        setOnSiteDateGroups: (state: any, action: any) => {
            state.onSiteDateGroups =  action.payload;
            return state;
        },
        setInterviewDetails: (state: any, action: any) => {
            state.interviewDetails =  action.payload;
            return state;
        },
        resetInterviewDetails: (state: any) => {
            state.interviewDetails =  initialData.interviewDetails;
            return state;
        },
        setOnSiteStandards: (state: any, action: any) => {
            state.onSiteStandards = action.payload;
            return state;
        },
        resetOnSiteStandards: (state: any) => {
            state.onSiteStandards = initialData.onSiteStandards;
            return state;
        },
        setSelectedOnSiteCriteria: (state: any, action: any) => {
            state.selectedOnSiteCriteria = action.payload;
            return state;
        },
        resetSelectedOnSiteCriteria: (state: any) => {
            state.selectedOnSiteCriteria = initialData.selectedOnSiteCriteria;
            return state;
        },
        setSelectedOnSiteSession: (state: any, action: any) => {
            state.selectedOnSiteSession = action.payload;
            return state;
        },
        resetSelectedOnSiteSession: (state: any) => {
            state.selectedOnSiteSession = initialData.selectedOnSiteSession;
            return state;
        },
        setInterviewers: (state: any, action: any) => {
            state.interviewers = action.payload;
            return state;
        },
        setInterviewView: (state:any, action) => {
            state.interviewView = action.payload;
            return state;
        },
        setInterviewTeams: (state: any, action: any) => {
            state.interviewTeams = action.payload;
            return state;
        },
        setEvalReportCriteria: (state: any, action: any) => {
            state.evalReportCriteria =  action.payload;
            return state;
        },
        setEvalReportStandards: (state: any, action: any) => {
            state.evalReportStandards = action.payload;
            return state;
        },
        resetEvalReportStandards: (state: any) => {
            state.evalReportStandards = initialData.evalReportStandards;
            return state;
        },
        setSelectedEvalReportCriteria: (state: any, action: any) => {
            state.selectedEvalReportCriteria = action.payload;
            return state;
        },
        resetSelectedEvalReportCriteria: (state: any) => {
            state.selectedEvalReportCriteria = initialData.selectedEvalReportCriteria;
            return state;
        },
        setActionsList: (state: any, action: any) => {
            state.actionsList = action.payload;
            return state;
        },
        resetActionsList: (state: any) => {
            state.actionsList = initialData.actionsList;
            return state;
        },
        setStaffList: (state: any, action: any) => {
            state.staffList = action.payload;
            return state;
        },
        resetStaffList: (state: any) => {
            state.staffList = initialData.staffList;
            return state;
        },
        setProjectList: (state: any, action: any) => {
            state.projectList = action.payload;
            return state;
        },
        resetProjectList: (state: any) => {
            state.projectList = initialData.projectList;
            return state;
        },
        setDepartmentList: (state: any, action: any) => {
            state.departmentList = action.payload;
            return state;
        },
        resetDepartmentList: (state: any) => {
            state.departmentList = initialData.projectList;
            return state;
        },
        addNewSaAction: (state: any, action: any) => {
            state.newSaAction = action.payload;
            return state;
        },
        resetAddNewSaAction: (state: any) => {
            state.newSaAction = initialData.newSaAction;
            return state;
        },
        setCurrentEvaluationTab: (state:any, action:any) => {
            state.currentEvaluationTab = action.payload;
            return state;
        },
        setCriteriaActions: (state:any, action:any) => {
            state.saCriteriaActions = action.payload;
            return state;
        },
        setCriteriaEvidences: (state:any, action:any) => {
            state.saCriteriaEvidences = action.payload;
            return state;
        },
        setSaCriteriaComments: (state:any, action:any) => {
            state.saCriteriaComments = action.payload;
            return state;
        },
        setOffSiteCriteriaComments: (state:any, action:any) => {
            state.offSiteCriteriaComments = action.payload;
            return state;
        },
        setOnSiteCriteriaComments: (state:any, action:any) => {
            state.onSiteCriteriaComments = action.payload;
            return state;
        },
        setEvalCriteriaComments: (state:any, action:any) => {
            state.evalCriteriaComments = action.payload;
            return state;
        },
        setCommentContent: (state:any, action:any) => {
            state.commentContent = action.payload;
            return state;
        },
        resetCommentContent: (state:any) => {
            state.commentContent = initialData.criteriaComment;
            return state;
        },
        setSelectedCommentPhase: (state:any, action:any) => {
            state.selectedCommentPhase = action.payload;
            return state;
        },
        setCriteriaComment: (state:any, action:any) => {
            state.criteriaComment = action.payload;
            return state;
        },
        resetCriteriaComment: (state:any) => {
            state.criteriaComment = initialData.criteriaComment;
            return state;
        },
        setSelectedChatPhase: (state:any, action:any) => {
            state.selectedChatPhase = action.payload;
            return state;
        },
        setSelectedStandardsChat: (state:any, action:any) => {
            state.selectedStandardsChat = action.payload;
            return state;
        },
    }
})

export const { 
    setEvaluationSearchString,
    setCriteriaGroups,
    updateCurrentSection,
    setInitialSection,
    setSaCriteria,
    setSaStandards,
    resetSaStandards,
    setSelectedSaCriteria,
    resetSelectedSaCriteria,
    setSaActions,
    resetSaActions,
    setSaEvidences,
    resetSaEvidences,
    setSaComments,
    resetSaComments,
    setOffSiteCriteria,
    setOffSiteStandards,
    setSelectedOffSiteCriteria,
    resetSelectedOffSiteCriteria,
    setOnSiteCriteria,
    setOnSiteDateGroups,
    setInterviewDetails,
    resetInterviewDetails,
    setOnSiteStandards,
    setSelectedOnSiteCriteria,
    resetSelectedOnSiteCriteria,
    setSelectedOnSiteSession,
    resetSelectedOnSiteSession,
    setInterviewers,
    setInterviewView,
    setInterviewTeams,
    setEvalReportCriteria,
    setEvalReportStandards,
    setSelectedEvalReportCriteria,
    resetSelectedEvalReportCriteria,
    resetOffSiteStandards,
    resetOnSiteStandards,
    resetEvalReportStandards,
    setActionsList,
    resetActionsList,
    setStaffList,
    resetStaffList,
    setProjectList,
    resetProjectList,
    setDepartmentList,
    resetDepartmentList,
    addNewSaAction,
    resetAddNewSaAction,
    setCurrentEvaluationTab,
    setCriteriaActions,
    setCriteriaEvidences,
    setSaCriteriaComments,
    setOffSiteCriteriaComments,
    setOnSiteCriteriaComments,
    setEvalCriteriaComments,
    setCommentContent,
    resetCommentContent,
    setSelectedCommentPhase,
    setCriteriaComment,
    resetCriteriaComment,
    setSelectedChatPhase,
    setSelectedStandardsChat,
 } = evaluation.actions;
export default evaluation.reducer;