import { colors } from "../pallette/colors";

export const DATE_FORMAT = 'yyyy.MM.dd';

export const formatDate = (dateString:any) => {
  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
  const day = String(date.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
};

export const formatDateTime = (dateString:any) => {
  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
  const day = String(date.getDate()).padStart(2, '0');
  const hour = String(date.getHours());
  const mins = String(date.getMinutes());
  return `${year}-${month}-${day} ${hour}:${mins}`;
};

export const formatHHmmToDateTime = (timeString:any) => {
  const [hours, minutes] = timeString.split(':'); // Split the time into hours and minutes
  
  const today = new Date(); // Get today's date
  today.setHours(hours); // Set the hours
  today.setMinutes(minutes); // Set the minutes
  today.setSeconds(0); // Optional: set seconds to 0 if needed
  
  return formatDateTime(today);
}

export const formatDateTimeToHHmm = (dateTime:any) => {  
  const today = new Date(dateTime); // Get today's date
  const hours = today.getHours();
  const minutes = today.getMinutes();
  
  return `${hours}:${minutes}`;
}

export const formatDateTimeTomm = (dateTime:any) => {  
  const today = new Date(dateTime); 
  const minutes = today.getMinutes();
  
  return `${minutes}`;
}

export const formatMMToDateTime = (mmString:any) => {
  const today = new Date();
  today.setMinutes(mmString); // Set the minutes
  today.setSeconds(0); // Optional: set seconds to 0 if needed
  
  return formatDateTime(today);
  
}

export const genUID: any = (str:any) => {
  if(str == undefined) {
    return '';
  }
  return str.slice(1);
};


  // Helper function to convert time string (HH:MM) to minutes from midnight
export const timeToMinutes = (time: string) => {
    if (time == undefined || time == '0') {
        return 0;
    }
    const [hours, minutes] = time.split(':').map(Number);
    return hours * 60 + minutes;
};

export const detectConflicts = (filteredData: any[]) => {
  const conflictIndices: number[] = [];

  for (let i = 0; i < filteredData.length; i++) {
      const currentRecord = filteredData[i];
      const currentStartTime = timeToMinutes(currentRecord?.startTimeStr ? currentRecord?.startTimeStr : 0);
      const currentEndTime = currentStartTime + parseInt(currentRecord.duration);

      // Compare with every record that comes after the current record
      for (let j = i + 1; j < filteredData.length; j++) {
          const nextRecord = filteredData[j];
          const nextStartTime = timeToMinutes(nextRecord?.startTimeStr ? nextRecord?.startTimeStr : 0);
          const nextEndTime = nextStartTime + parseInt(nextRecord.duration);

          // Detect conflict (if the next record starts before the current one ends, or overlaps)
          if (nextStartTime != 0 && currentEndTime != 0 && currentStartTime != 0 && nextEndTime != 0 && 
            nextStartTime <= currentEndTime && currentStartTime <= nextEndTime) {
              conflictIndices.push(i); // Current record in conflict
              conflictIndices.push(j); // Next record in conflict
          }
      }
  }
  return conflictIndices;
};

//status colors and labels
export const commonStatus = [
  {statusName: 'Not Evaluated', color: colors.statusColors.notEvaluated},
  {statusName: 'Not Met', color: colors.statusColors.notMet},
  {statusName: 'Partial', color: colors.statusColors.partial},
  {statusName: 'Met', color: colors.statusColors.met}
];

export const interimReportStatus = [
  {statusName: 'Not Met', color: colors.statusColors.notMet},
  {statusName: 'Partial', color: colors.statusColors.partial},
  {statusName: 'Met', color: colors.statusColors.met}
];

export const finalReportStatus = [
  {statusName: 'Not Started', color: '#FFF'},
  {statusName: 'Not Met', color: colors.statusColors.notMet},
  {statusName: 'Partial', color: colors.statusColors.partial},
  {statusName: 'Met', color: colors.statusColors.met}
];

export const rabgStatus = [
  {statusName: 'MISSED', color: colors.lightishRed},
  {statusName: 'AT_RISK', color: colors.tangerine},
  {statusName: 'ON_TRACK', color: colors.vibrantGreen},
  {statusName: 'COMPLETE', color: colors.gold},
  {statusName: 'NOT_STARTED', color: colors.middleGrey}
];

export const siteReviewStatus = [
  {statusName: 'NOT_EVALUATED', color: colors.notEvalGrey},
  {statusName: 'NOT_MET', color: colors.lightishRed},
  {statusName: 'PARTIAL', color: colors.tangerine},
  {statusName: 'MET', color: colors.shamrockGreen},
];

export const liecenceHome = [
  {statusName: 'NOT_MET', color: colors.lightishRed},
  {statusName: 'PARTIAL', color: colors.tangerine},
  {statusName: 'MET', color: colors.vibrantGreen},
  {statusName: '_', color: colors.gold},
  {statusName: 'NOT_EVALUATED', color: colors.middleGrey},
];

export const liecenceAdmin = [
  {statusName: 'DRAFT', color: colors.middleGrey},
  {statusName: 'ISSUED', color: colors.vibrantGreen},
];

export const calcTaskPercentage = (taskData:any) => {
  if (taskData.length === 0) return 0;

  const completedCount = taskData.filter((task:any) => task.checked).length;
  const percentage = (completedCount / taskData.length) * 100;

  return percentage;
}