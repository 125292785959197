import React, { useEffect, useState } from 'react';
//internal and external components import
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import { TableRow, Checkbox, Chip, TextField, ThemeProvider, createTheme, Tooltip  } from '@mui/material';
import { stableSort, getComparator, sortData } from '../../common/SortSupport';
import { EnhancedTableHead } from '../EnhancedTableHead';
import { Box } from '@mui/material';
import { AvatarAndName } from '../AvatarAndName';
import StatusIndicatorSmall from '../StatusIndicatorSmall';
import logo from '../../assets/icons/JLogo.svg';
import ThreeDotMenu from '../ThreeDotMenu';
import SelectBoxPrimary from '../SelectBoxPrimary';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import SelectBox from '../SelectBox';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faCirclePlus, faEyeSlash, faLink, faLinkSlash, faStar, faTrash, faXmark } from '@fortawesome/free-solid-svg-icons';
import { colors } from '../../common/pallette/colors';
import ActionDetailPopper from '../../containers/ActionDetailPopper';
import DateSelector from '../DateSelector';
import { useSelector } from 'react-redux';
import { getStandardListByClub } from '../../api/standards';
import { getSelfAssessmentCriteria } from '../../api/evaluation';
import SearchSelectBox from '../SearchSelectBox';
import { getLicenceCriterias, getStandardListByClubLicence } from '../../api/licence';
import NestedThreeDotMenu from '../NestedThreeDotMenu';
import ButtonDefault from '../ButtonDefault';
import { useTranslation } from 'react-i18next';

const theme = createTheme({
  components: {
    MuiTableCell: {
      styleOverrides: {
        root: {
          padding: '5px'
        }
      }
    },
    MuiStack: {
      styleOverrides: {
        root: {
          display: 'inline',
          width: '90px'
        }
      }
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          minWidth: '90px !important',
        }
      }
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          fontSize: 12
        }
      }
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          width: 18,
          height: 18
        }
      }
    }
  },
})

const PerfActionLicenceStandardsTable = (props: any) => {
    const { data, buttonLabel, manageLinkStandard } = props;

    
    const head = [
      {
          key: 'standards', displayName: 'standard',
      },
      {
          key: 'criteria', displayName: 'criteria',
      },
      {
          key: '', displayName: ''
      }
  ];
  
  const currentClub = useSelector((state:any) => state.clubDetails);
  const [standardsList, setStandardsList] = useState([]);
  const [criteriaList, setCriteriaList] = useState([]);
  const threeDotMenuOptions = 
  [
    {label: 'Unlink Action', icon: faLinkSlash, isDisabled: false},
    {label: 'Archive Action', icon: faEyeSlash, isDisabled: false},
    {label: 'Delete Action', icon: faTrash, isDisabled: false},
  ];
  const initialStandard = {
    standardName: null,
    criteriaName: null,
    licenceCriteriaId: null,
    licenceStandardId: null,
    isEdit: true,
  }
  const [t] = useTranslation();

  //SORTING contents
  const [order, setOrder] = React.useState('');
  const [orderBy, setOrderBy] = React.useState('');
  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: any,
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };
  const visibleRows = React.useMemo(
    () =>
      data?.length > 0 &&
      sortData(data, orderBy, order),
    [order, orderBy, data],
  );

  const fetchStandards = async () => {
    const response = await getStandardListByClubLicence({clubId: currentClub.id});
    setStandardsList(response);
  }
  const fetchCriteria = async () => {
    const response = await getLicenceCriterias({clubId: currentClub.id});
    setCriteriaList(response);
  }
  const handleLinkStandard = () => {
      currentClub.id && standardsList.length == 0 &&
      fetchStandards();
      currentClub.id && criteriaList.length == 0 &&
      fetchCriteria();
      manageLinkStandard('addStandard', initialStandard, null);
  }
    return(
        <React.Fragment>
          <ThemeProvider theme={theme}>
          <Box sx={{ position: 'relative' }}>
              <TableContainer sx={{ maxHeight: '34vh', overflowY: 'auto' }}>
                <Table stickyHeader size="small" aria-label="a dense table">
                    <EnhancedTableHead
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={handleRequestSort}
                    tableHeads={head}
                    />
                    <TableBody>
                    {
                        visibleRows && visibleRows?.length ?
                        visibleRows.map((d: any, i: number) => (
                        <TableRow
                        className='hoverBG'
                        sx={{ cursor: 'pointer' }}
                        key={`actions-standards-table-row-${i}`}
                        >
                            <TableCell className='fs12'>
                              {
                                d?.isEdit ?
                                <SearchSelectBox
                                options={standardsList}
                                variant={'standard'}
                                selectedValue={d.standardName}
                                manageSelect={(item:any) => {
                                  if(item != null && item != undefined) {
                                    var selectedCriteria:any = criteriaList.find((c:any) => c.id == item.licenceCriteriaId);
                                    var tempObj = {
                                      ...initialStandard,
                                      standardName: item.name,
                                      criteriaName: selectedCriteria?.name,
                                      licenceStandardId: item.id,
                                      licenceCriteriaId: item.licenceCriteriaId
                                    }
                                    manageLinkStandard('updateStandard', tempObj, i);
                                  } else {
                                    manageLinkStandard('unlinkStandard', null, i);
                                  }
                                }}
                                /> :
                                d.standardName
                              }
                            </TableCell>
                            <TableCell className='fs12'>{d.criteriaName}</TableCell>
                            <TableCell className='fs12'>
                              {/* <NestedThreeDotMenu options={threeDotMenuOptions} manageClick={() => {}}/> */}
                            </TableCell>
                        </TableRow>
                        ))
                        :
                        <TableRow>{'No Records Found'}</TableRow>
                      }
                    </TableBody>
                </Table>
              </TableContainer>
              <Box sx={{ float: 'left', mt: 2 }}>
                  <ButtonDefault
                  variant={'outlined'}
                  value={buttonLabel}
                  manageOnClick={() => {handleLinkStandard()}}
                  icon={<FontAwesomeIcon className={'fs12'} icon={faLink} />}
                  />
              </Box>
            </Box>
          </ThemeProvider>
        </React.Fragment>
    )
}

export default PerfActionLicenceStandardsTable;