import React, { useEffect, useState } from 'react';
import './styles.css';
//internal or external components import
import { Box, Typography, Button, Paper, Tab } from '@mui/material';
import ThreeDotMenu from '../../components/ThreeDotMenu';
import Textarea from '@mui/joy/Textarea';
import { CssVarsProvider as JoyCssVarsProvider } from '@mui/joy/styles';
import StatusButton from '../../components/StatusButton';
import TargetAcheivedStars from '../../components/TargetAcheivedStars';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import FinalReportOverview from '../PhaseHeader';
import ClubCriteria from '../ClubCriteria';
import { useDispatch, useSelector } from 'react-redux';
import { setSelectedCommentBoxTab } from '../../redux/reducers/common';
import SelectBox from '../../components/SelectBox';
import NestedThreeDotMenu from '../../components/NestedThreeDotMenu';
import { faEyeSlash, faTrash } from '@fortawesome/free-solid-svg-icons';
import { colors } from '../../common/pallette/colors';
import ChatBox from '../ChatBox';
import { resetCriteriaComment, setCommentContent, setCriteriaComment, setOnSiteCriteriaComments, setSelectedCommentPhase } from '../../redux/reducers/evaluation';
import { getClubReviewsInfo, getCriteriaComments, saveCriteriaComments, saveEvaluationReportStandardDetails, saveOffSiteEvaluation, syncQuestions, updateOnSiteCriteriaComments } from '../../api/evaluation';
import { useTranslation } from 'react-i18next';
import { genUID } from '../../common/StaticExports';

export const ReportComment = (props: any) => {
    const tabLabels:any = useSelector((state:any) => state.common.commentBoxTabs);
    const selectedTab:any = useSelector((state:any) => state.common.selectedCommentBoxTab);
    //manages editing of standard level comments
    // const commentContent = useSelector((state:any) => state.evaluation.commentContent);
    const criteriaComment = useSelector((state:any) => state.evaluation.criteriaComment);
    const currentClub = useSelector((state:any) => state.clubDetails);
    const selectedCommentPhase = useSelector((state:any) => state.evaluation.selectedCommentPhase);
    const currentEvalTab = useSelector((state:any) => state.evaluation.currentEvaluationTab);
    const commentPhases = useSelector((state:any) => state.evaluation.commentPhases);
    const selectedSaCriteria = useSelector((state:any) => state.evaluation.selectedSaCriteria);
    const selectedOnSiteCriteria = useSelector((state:any) => state.evaluation.selectedOnSiteCriteria);
    const commentContent = useSelector((state:any) => state.evaluation.commentContent);
    const onSiteCriteriaComments = useSelector((state:any) => state.evaluation.onSiteCriteriaComments);
    const selectedChatPhase = useSelector((state:any) => state.evaluation.selectedChatPhase);
    const currentSection = useSelector((state:any) => state.evaluation.currentSection);
    const [value, setValue] = useState('');
    const [editComment, setEditComment] = useState(false);
    const [selectedCriteria, setSelectedCriteria]:any = useState({});
    const selectedStandardsChat = useSelector((state:any) => state.evaluation.selectedStandardsChat);
    const dispatch = useDispatch();
    const [t] = useTranslation();
    
    const handleChange = (event: React.SyntheticEvent, newValue: any) => {
        dispatch(setSelectedCommentBoxTab(newValue));
    };

    useEffect(() => {
        switch(currentSection) {
            case t('EVALUATION'):
                Object.keys(selectedSaCriteria).length &&
                setSelectedCriteria(selectedSaCriteria);
                break;
            case t('ON_SITE'):
                Object.keys(selectedOnSiteCriteria).length &&
                setSelectedCriteria(selectedOnSiteCriteria);
                break;
        }
    }, [currentSection, selectedOnSiteCriteria]);

  const manageSaveComment = async () => {
    setEditComment(!editComment);
    if(editComment) {
        if(currentEvalTab == t('ON_SITE')) {
            const response = await updateOnSiteCriteriaComments({data: commentContent});
            dispatch(setOnSiteCriteriaComments(response));
        } else {
            saveCriteriaComments({id: criteriaComment?.id ? criteriaComment.id : 0,
                 data: {...criteriaComment,
                    criteriaId: selectedSaCriteria.id, clubId: currentClub.id,
                 }}).then((res:any) => {
                    dispatch(setCriteriaComment(res || Object));
                 });
        }
    }
  }

  const manageChange = (value:any) => {
    if (currentEvalTab == t('ON_SITE')) {
        const tempArr:any = onSiteCriteriaComments.map((x:any) => {
            if (x.sessionId == commentContent.sessionId) {
                dispatch(setCommentContent({ ...x, comment: value }));
                return { ...x, comment: value }
            } return x;
        });
        dispatch(setOnSiteCriteriaComments(tempArr));
    } else {
        dispatch(setCriteriaComment({...criteriaComment, [fetchKey(currentEvalTab)]: value}));
    }
  }

  useEffect(() => {
    const fetchCriteriaComments = async () => {
        const response = await getCriteriaComments({ clubId: currentClub.id, criteriaId: selectedCriteria.id});
        return response;
    }
    currentClub && currentClub.id && selectedCriteria && selectedCriteria.id &&
    fetchCriteriaComments().then((res:any) => {
        if(res != null) {
            dispatch(setCriteriaComment(res || Object));
        } else {
            dispatch(resetCriteriaComment());
        }
    })
  }, [selectedCriteria]);

  const fetchKey:any = (value:any) => {
    switch(value) {
        case t('EVALUATION'):
            return 'selfAssessmentComment';
        case t('OFF_SITE'):
            return 'offSiteComment';
        case t('ON_SITE'):
            return 'comment';
        case t('INTERIM_REPORT'):
            return 'interimReportComment';
        case t('FINAL_REPORT'):
            return 'finalReportComment';
    }
  }

  const fetchCommentValue = () => {
    if (currentEvalTab == t('ON_SITE')) {
        if (commentContent) {
            const selectedOnSiteItem = onSiteCriteriaComments.find((x:any) => x.sessionId == commentContent.sessionId);
            return selectedOnSiteItem?.comment ? selectedOnSiteItem?.comment : '';
        } else return '';
    } return criteriaComment[fetchKey(currentEvalTab)];
  }

    return(
        <React.Fragment>
            <Paper className={'ReportCommentWrapper'} elevation={1} sx={{ marginLeft: 3, height: '100%' }}>
                <Box sx={{ height: 'calc(100% - 32px)' }}>
                    <TabContext value={selectedTab}>
                        <Box className={'commentBoxHeader'}>
                            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <TabList onChange={handleChange}>
                                {
                                    tabLabels.map((tabName: string, index: number) => {
                                        return <Tab label={tabName} value={tabName} />;
                                    })
                                }
                            </TabList>
                            </Box>
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <Typography className='fs13 mr10'>{selectedTab == 'COMMENTS' ? 'Comment Phase:' : 'Chat Phase:'}</Typography>
                                <Typography className='fs13 fw800'>{selectedTab == 'COMMENTS' ? currentEvalTab : selectedChatPhase}</Typography>
                                {
                                    selectedTab == 'CHAT' &&
                                    <Box
                                    sx={{
                                        backgroundColor: colors.blue.navy,
                                        fontSize: '10px',
                                        color: colors.white.bright,
                                        p: '2px 8px',
                                        borderRadius: '16px',
                                        marginLeft: '6px',
                                    }}>
                                        {selectedStandardsChat?.uid ? genUID(selectedStandardsChat.uid) : 'N/A'}
                                    </Box>
                                }
                            </Box>
                        </Box>
                        <TabPanel value='COMMENTS' className='tabPanelComments'>
                            <Box className='commentBoxContainer' sx={{ height: '100%' }}>
                                <Box sx={{ width: '100%' }}>
                                    {
                                        currentEvalTab == t('ON_SITE') && commentContent?.question && commentContent?.question != undefined &&
                                        <Typography className='fs13 fw700' sx={{ textAlign: 'left' }}>
                                            {commentContent.question}
                                        </Typography>
                                    }
                                    <JoyCssVarsProvider>
                                        <Textarea 
                                        sx={{ borderRadius: '6px', mt: 1, backgroundColor: colors.grey.bright, border: 0, height: '100%', color: colors.black.dark}} 
                                        onChange={(e) => {manageChange(e.target.value)}} 
                                        placeholder='Add a comment' 
                                        // minRows={true ? 6 : 10} 
                                        // maxRows={10} 
                                        disabled={!editComment}
                                        value={fetchCommentValue()}/>
                                    </JoyCssVarsProvider>
                                </Box>
                                <Box sx={{ ml: '10px', mt: 'auto' }}>
                                    <Button disableElevation variant='contained' size='small' onClick={() => {manageSaveComment()}}>
                                        {!editComment ? 'Edit' : 'Save'}
                                    </Button>
                                </Box>
                            </Box>
                        </TabPanel>
                        <TabPanel value='CHAT' sx={{ p: 0, height: 'calc(100% - 55.25px)' }}>
                            <Box className='chatWrapper' sx={{ minHeight: '145px', height: '100%' }}>
                                <ChatBox />
                            </Box>
                        </TabPanel>
                        {/* <TabPanel value='MY NOTES' sx={{ p: 0 }}>
                            <Box className='commentBoxContainer'>
                                <Box sx={{ width: '100%' }}>
                                    <JoyCssVarsProvider>
                                        <Textarea sx={{ borderRadius: '6px', mt: 1, backgroundColor: colors.grey.bright, border: 0 }} onChange={(e) => setValue(e.target.value)} placeholder='Add report comment text' minRows={data ? 6 : 10} maxRows={10} value={value}/>
                                    </JoyCssVarsProvider>
                                </Box>
                                <Box sx={{ ml: '10px', mt: 'auto' }}>
                                    <Button disableElevation variant='contained' size='small' onClick={() => {}}>
                                        {'Edit'}
                                    </Button>
                                </Box>
                            </Box>
                        </TabPanel> */}
                    </TabContext>
                </Box>
            </Paper>
        </React.Fragment>
    )
}