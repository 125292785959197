import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from "react-redux";
//internal and external components import
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import { Checkbox, TableRow, TextField, Tooltip } from '@mui/material/';
import { Box } from '@mui/material';
import ThreeDotMenu from '../ThreeDotMenu';
import { EnhancedTableHead } from '../EnhancedTableHead';
import TableSkeleton from '../TableSkeleton';
import { sortData } from '../../common/SortSupport';
import { faCirclePlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import CheckBox from '../CheckBox/CheckBox';
import ButtonDefault from '../ButtonDefault';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import { faTrashCan } from '@fortawesome/free-regular-svg-icons';
import { TextFieldWithoutBorders } from '../TextFieldWithoutBorders';
import DateSelector from '../DateSelector';
import { formatDate } from '../../common/StaticExports';

const TasksTable = (props:any) => {

  const { data, isLoading, handleChange } = props;

  const threeDotMenuOptions = 
  [
    {label: 'Delete Task', icon: faTrash, isDisabled: false},
  ];
  const initialTask = {
    "id": null,
    "task": null,
    "checked": false
  }

  const [t] = useTranslation();

  //supporting contents
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('');

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: any,
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };
  
  let visibleRows = React.useMemo(
    () =>
      data && data.length > 0 &&
      sortData(data, orderBy, order),
    [order, orderBy, data],
  );

  const head = [
    {
      key: 'task', displayName: 'task',
    },
    {
      key: 'dueDate', displayName: 'due date',
    },
    {
      key: 'checked', displayName: 'complete',
    },
    {
      key: '', displayName: '',
    },
  ]

  //table contents
    return(
        <React.Fragment>
            <Box sx={{ position: 'relative' }}>
              <TableContainer 
              sx={{ 
                "& .MuiTable-root": {
                  border: 0,
                },
                "& .MuiTableCell-root": {
                  borderBottom: "none", // Remove bottom borders from table cells
                },
                "& .MuiTableRow-root": {
                  border: "none", // Remove row borders
                }
              }}>
                <Table stickyHeader size="small" aria-label="a dense table">
                    <EnhancedTableHead
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={handleRequestSort}
                    tableHeads={head}
                    />
                    {
                      !isLoading && visibleRows.length ?
                      <TableBody>
                        {
                          visibleRows.map((d: any, i: number) => (
                            <>
                              <TableRow
                              className='hoverBG'
                              sx={{ cursor: 'pointer' }}
                              key={`tasks-table-row-${i}`}
                              >
                              <TableCell className='fs12' width={'900px'}>
                                <TextFieldWithoutBorders 
                                value={d.task}
                                handleChange={(e:any) => {handleChange('task', e.target.value, i)}}
                                />
                              </TableCell>
                              <TableCell>
                                <DateSelector variant='standard' dateValue={d?.dueDate ? formatDate(d?.dueDate) : null} 
                                manageChange={(e:any) => {
                                  const selectedDate = new Date(e);
                                  handleChange('dueDate', selectedDate, i);
                                }}
                                />
                              </TableCell>
                              <TableCell className='fs12'>
                                <Checkbox sx={{ padding: '0px' }} 
                                checked={d.checked} 
                                onChange={(e) => {handleChange('checked', e.target.checked, i)}}
                                />
                              </TableCell>
                              <TableCell className='fs12'>
                                {/* <ThreeDotMenu options={threeDotMenuOptions} manageClick={(e:any) => {}}  /> */}
                                <Tooltip title={t('DELETE')} arrow placement='top'>
                                  <FontAwesomeIcon className='fs18' 
                                  icon={faTrashCan}
                                  onClick={() => {handleChange('deleteTask', null, i)}} />
                                </Tooltip>
                              </TableCell>
                              </TableRow>
                            </>
                          ))
                        }
                      </TableBody>
                      :
                      (
                        !isLoading && !visibleRows ?
                        <TableBody>
                          <TableRow>{'No Records Found'}</TableRow>
                        </TableBody> :
                        <TableBody>
                          <TableSkeleton rowCount={4} cellCount={3} />
                        </TableBody>
                      )
                    }
                </Table>
              </TableContainer>
              <Box sx={{ float: 'left', mt: 2 }}>
                  <ButtonDefault
                  variant={'outlined'}
                  value={t('ADD_TASK')}
                  manageOnClick={() => {handleChange('addTask', initialTask, null)}}
                  icon={<FontAwesomeIcon className={'fs13'} icon={faCirclePlus} />}
                  />
              </Box>
            </Box>
        </React.Fragment>
    )
}

export default TasksTable;