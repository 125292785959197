import * as React from 'react';
import { useSelector, useDispatch } from "react-redux";
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import { Actions } from '../Actions';
import { Box, createTheme, ThemeProvider } from '@mui/material';
import { EvidenceWrapper } from '../../components/EvidenceWrapper';
import { CommentsOverviewBox } from '../CommentsOverviewBox';
import { Accordion, AccordionSummary } from '../../common/AccordionsCustomized';
import { ReportComment } from '../ReportComment';
import StatusButton from '../../components/StatusButton';
import { comments } from '../../assets/staticData';
import LicenceStandardsTable from '../../components/LicenceStandardsTable';
import { licenceStandards } from '../../assets/staticData';
import { getClubReviewsInfo, getEvaluationCriteriaStandards, getOnSiteCriteriaStandards, getStandardsEvidences } from '../../api/evaluation';
import ClubStandards from '../ClubStandards';
import EvidenceList from '../../components/EvidenceList';
import { setCriteriaEvidences, setInterviewDetails, setSaEvidences } from '../../redux/reducers/evaluation';
import EvidencesTable from '../../components/EvidencesTable';
import Dragger from '../../components/Dragger';
import { getLicenceStandardsEvidences } from '../../api/licence';
import { setLicenceCriteriaEvidences } from '../../redux/reducers/licence';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import InterviewStandards from '../InterviewStandards';
import { useTranslation } from 'react-i18next';
import ResizableComponent from '../../components/ResizableComponent';

export default function C7Accordion(props: {data: any, module: String}) {
  const { data, module } = props  
  const [expanded, setExpanded] = React.useState<string | false>('panel1');
  const [comment, setComment] = React.useState('');
  const dispatch = useDispatch();
  const [isLoadingEvidences, setIsLoadingEvidences] = React.useState(false);
  const currentClub = useSelector((state:any) => state.clubDetails);
  const evalEvidences = useSelector((state:any) => state.evaluation.saCriteriaEvidences);
  const licenceEvidences = useSelector((state:any) => state.licence.criteriaEvidences);
  const currentEvalCriteria = useSelector((state:any) => state.evaluation.selectedSaCriteria);
  const selectedLicenceCriteria = useSelector((state: any) => state.licence.selectedLicenceCriteria);
  const [t] = useTranslation();

  const theme = createTheme({
    components: {
      MuiCollapse: {
        styleOverrides: {
          root: {
            height: 'calc(100% - 32px) !important',
          },
          wrapper: {
            height: '100%',
          }
        },
      },
      MuiAccordion: {
        styleOverrides: {
          // root: {
          //   height: expanded == 'panel1' ? '100% !important' : '', 
          // },
          region: {
            height: '100% !important'
          }
        }
      },
      MuiAccordionDetails: {
        styleOverrides: {
          root: {
            height: expanded == 'panel1' ? '100% !important' : '',
          }
        }
      }
    }
  })

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };
  
  React.useEffect(() => {
    const fetchEvidences = async () => {
      setIsLoadingEvidences(true);
      switch(module) {
        case t('EVALUATION'):
          const evalResponse = await getStandardsEvidences({standardId: 0, clubId: currentClub.id, criteriaId: currentEvalCriteria.id});
          dispatch(setCriteriaEvidences(evalResponse));
          break;
        case t('LICENCE'):
          const licenceResponse = await getLicenceStandardsEvidences({standardId: 0, clubId: currentClub.id, criteriaId: selectedLicenceCriteria.id});
          dispatch(setLicenceCriteriaEvidences(licenceResponse));
          break;
      }
      setIsLoadingEvidences(false);
    }
    
    expanded == 'panel3' &&
    fetchEvidences();
  }, [expanded])

  const [topHeight, setTopHeight] = React.useState(0);
  const [bottomHeight, setBottomHeight] = React.useState(300);
  const containerRef = React.useRef(null);

  const handleMouseDown = (e:any) => {
    setTopHeight(250);
    const startY = e.clientY;
    const startTopHeight = topHeight;
    const startBottomHeight = bottomHeight;

    const handleMouseMove = (moveEvent:any) => {
      const offset = moveEvent.clientY - startY;
      setTopHeight(startTopHeight + offset);
      setBottomHeight(startBottomHeight - offset);
    };

    const handleMouseUp = () => {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
    };

    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);
  };

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ height: '100%' }}>
        <Accordion sx={expanded !== 'panel1' ? { } : { height: '100%' }} expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
          <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
            <Typography className={'uppercase'} sx={{fontSize: 12}}>Standards and Evaluation</Typography>
          </AccordionSummary>
          <MuiAccordionDetails sx={expanded !== 'panel1' ? { display: 'none' } : { height: '100%' }}>
            {module == t('LICENCE') ? 
            <LicenceStandardsTable /> :
            <ResizableComponent captureData={(d:any) => {setComment(d)}}/>
            } 
          </MuiAccordionDetails>
        </Accordion>
        {
          module != t('ON_SITE') &&
          <>
          <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
            <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
              <Typography className={'uppercase'} sx={{fontSize: 12}}>Actions</Typography>
            </AccordionSummary>
            <MuiAccordionDetails sx={expanded !== 'panel2' ? { display: 'none' } : {}}>
              <Actions isOpen={expanded == 'panel2'} module={module} />
            </MuiAccordionDetails>
          </Accordion>
          <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
            <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
              <Typography className={'uppercase'} sx={{fontSize: 12}}>Evidence</Typography>
            </AccordionSummary>
            <MuiAccordionDetails sx={expanded !== 'panel3' ? { display: 'none' } : {}}>
              <Box sx={{ display: 'grid', gridTemplateColumns: 'auto', mt: 1 }}>
                {/* <EvidenceList data={evidences} isLoading={isLoadingEvidences} /> */}
                <EvidencesTable data={module == t('EVALUATION') ? evalEvidences : licenceEvidences} isLoading={isLoadingEvidences}/>
              </Box>
            </MuiAccordionDetails>
          </Accordion>
          </>
        }
      </Box>
    </ThemeProvider>
  );
}