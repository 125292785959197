import React from "react";
import { Box, InputLabel, ThemeProvider, createTheme } from "@mui/material";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from "dayjs";

const theme = createTheme({
    components:{
        MuiInput: {
            styleOverrides: {
                input: {
                    height: 'auto',
                    fontSize: '13px'
                }
            }
        },
        MuiStack: {
            styleOverrides: {
                root: {
                    paddingTop: '0px !important'
                }
            }
        }
    }
})

const DateSelector = (props:any) => {
    const { variant, label, dateValue, manageChange } = props;
    return(
        <ThemeProvider theme={theme}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Box sx={{ width: '100%' }}>
                {(label && label !== '')  && <InputLabel className='selectBoxLabel'>{label}</InputLabel>}
                <DatePicker label=""
                onChange={manageChange}
                value={dateValue ? dayjs(dateValue) : null}
                slotProps={{
                textField: {
                    variant: variant ? variant : 'outlined',
                    size: 'small',
                    fullWidth: true,
                }
                }}
                />
                </Box>
            </LocalizationProvider>
        </ThemeProvider>
    )
}

export default DateSelector;