import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import HttpApi from 'i18next-http-backend';
import { baseUrl } from './api/config';

i18n
  .use(HttpApi)
  .use(initReactI18next)
  .init({
    backend: {
      loadPath: `${baseUrl}/api/uilabels/{{lng}}`,
    },
    keySeparator: false,
    interpolation: {
      escapeValue: false,
    },
    debug: false,

    react: {
      wait: true,
      useSuspense: true,
    },
    fallbackLng: localStorage.getItem('lang') || 'en', //need to work on the lang logic
  });

export default i18n;
