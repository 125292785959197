import React from "react";
import TextFieldDefault from "../TextFieldDefault";

export const TextFieldProject = (props:any) => {
    const { value, label, handleChange } = props;
    const manageHandleChange = (newValue:any) => {
        const tempObj = { name: newValue };
        handleChange(tempObj);
    }
    return (
        <React.Fragment>
            <TextFieldDefault 
            label={label}
            value={value}
            handleChange={(e:any) => {manageHandleChange(e.target.value)}} />
        </React.Fragment>
    )
}