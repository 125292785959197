import React from 'react';
import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux'; // Or useContext if you're using React Context
import CurrentClubIndicator from '../../containers/CurrentClubIndicator';
import AppDrawer from '../AppDrawer';
import { faBell, faCircleNodes, faFile, faLifeRing, faList } from "@fortawesome/free-solid-svg-icons";
import DraggableNotes from '../../containers/DraggableNotes';

const ProtectedRoute = (props:any) => {
    const { element } = props;
    let token:any = localStorage.getItem('_jauth_');
    const jlUser = [
        {title: 'Evaluation', icon: faList},
        {title: 'Licence', icon: faCircleNodes},
        {title: 'Performance Plan & Strategy', icon: faLifeRing},
        {title: 'Evidence Library', icon: faFile},
        {title: 'Notification', icon: faBell}
      ]
    if (!token) {
        return <Navigate to="/login" />;
    }

    return (
        <>
            <CurrentClubIndicator />
            <AppDrawer data={jlUser} />
            {element}
            <DraggableNotes />
        </>
    );
};

export default ProtectedRoute;
