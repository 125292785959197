import { Box, ClickAwayListener, Fade, Paper, Popper, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import "./styles.css";
import StatusIndicator from "../../components/StatusIndicator";
import StatusSelectBoxDefault from "../../components/StatusSelectBoxDefault";
import OwnerSelectBox from "../../components/OwnerSelectBox";
import DepartmentSelectBox from "../../components/DepartmentSelectBox";
import PerfActionDateSelector from "../../components/PerfActionDateSelector";
import { t } from "i18next";
import TasksTable from "../../components/TasksTable";
import PerfActionStandardsTable from "../../components/PerfActionStandardsTable";
import { CommentsOverviewBox } from "../CommentsOverviewBox";
import { ReportComment } from "../ReportComment";
import { getAction, updateAction } from "../../api/action";
import { useDispatch, useSelector } from "react-redux";
import { resetSelectedAction, setSelectedAction } from "../../redux/reducers/actionPlan";
import PerfActionLicenceStandardsTable from "../../components/PerfActionLicenceStandardsTable";
import ProgressBar from "../../components/ProgressBar";
import { calcTaskPercentage } from "../../common/StaticExports";
import { TextFieldWithoutBorders } from "../../components/TextFieldWithoutBorders";
import { TextAreaWithoutBorders } from "../../components/TextAreaWithoutBorders";
import { useTranslation } from "react-i18next";

const PerformanceActionDetailPopper = (props:any) => {
    const { isOpen, actionData, projectData, handleClickAway } = props;
    const dispatch = useDispatch();
    const selectedAction = useSelector((state:any) => state.actionPlan.selectedAction);
    const [isFetchingAction, setIsFetchingAction] = useState(false);
    const [isUpdatedData, setIsUpdatedData] = useState(false);
    const [t] = useTranslation();

    const fetchActionData = async () => {
        setIsFetchingAction(true);
        const response = await getAction({ id: actionData?.id,  pastStatusPeriod: 1});
        dispatch(setSelectedAction(response));
        setIsFetchingAction(false);
    }

    useEffect(() => {
        actionData && isOpen &&
        fetchActionData();
    }, [actionData]);

    const manageClickAway = () => {
        isUpdatedData && selectedAction && 
        updateAction({ actionId: selectedAction.id, action: selectedAction });
        dispatch(resetSelectedAction());
        setIsUpdatedData(false);
        handleClickAway();
    }
    
    const handleClickInsidePopper = (event: MouseEvent) => {
        event.stopPropagation();
    }

    //handling state updates
    //handling tasks
    const handleChangeTask = (label:any, updatedValue:any, index:any) => {
        var tempTasks = selectedAction?.tasks ? selectedAction?.tasks : [];
        switch(label) {
            case 'addTask':
                tempTasks = [...tempTasks, updatedValue];
                break;
            case 'deleteTask': 
                const newTasks = tempTasks.filter((_:any, i:any) => i !== index);
                tempTasks = newTasks;
                break;
            default:
                tempTasks = tempTasks.map((item:any, i:any) => {
                    if (i == index) {
                        return { ...item, [label]: updatedValue };
                    } return item;
                });
                break;
        }
        const tempAction = {...selectedAction, tasks: tempTasks};
        setIsUpdatedData(true);
        dispatch(setSelectedAction(tempAction));
    };
    //handling standards
    const handleChangeStandards = (label:any, updatedValue:any, index:any) => {
        var tempStandards = selectedAction?.standards ? selectedAction?.standards : [];
        switch(label) {
            case 'addStandard':
                tempStandards = [...tempStandards, updatedValue];
                break;
            case 'unlinkStandard':
                const newTasks = tempStandards.filter((_:any, i:any) => i !== index);
                tempStandards = newTasks;
                break;
            case 'updateStandard':
                tempStandards = tempStandards.map((item:any, i:any) => {
                    if (i == index) {
                        return updatedValue;
                    } return item;
                });
                break;
        }
        const tempAction = {...selectedAction, standards: tempStandards};
        setIsUpdatedData(true);
        dispatch(setSelectedAction(tempAction));
    };
    //handling licence standards
    const handleChangeLicenceStandards = (label:any, updatedValue:any, index:any) => {
        var tempStandards = selectedAction?.licenceStandards ? selectedAction?.licenceStandards : [];
        switch(label) {
            case 'addStandard':
                tempStandards = [...tempStandards, updatedValue];
                break;
            case 'unlinkStandard':
                const newTasks = tempStandards.filter((_:any, i:any) => i !== index);
                tempStandards = newTasks;
                break;
            case 'updateStandard':
                tempStandards = tempStandards.map((item:any, i:any) => {
                    if (i == index) {
                        return updatedValue;
                    } return item;
                });
                break;
        }
        const tempAction = {...selectedAction, licenceStandards: tempStandards};
        setIsUpdatedData(true);
        dispatch(setSelectedAction(tempAction));
    };
    //handling other changes
    const handleChangeState = (updatedData:any) => {
        var tempObj = { ...selectedAction, ...updatedData };
        setIsUpdatedData(true);
        dispatch(setSelectedAction(tempObj));
    }

    return (
        <React.Fragment>
            <Popper className='popperStyle' open={isOpen} transition>
                {({ TransitionProps }) => (
                    <ClickAwayListener onClickAway={() => {manageClickAway()}}>
                        <Fade {...TransitionProps} timeout={350}>
                            <Paper sx={{ p: 2, width: '1200px', height: '90vh', overflowY: 'auto', margin: 'auto', mt: '4vh' }} onClick={() => handleClickInsidePopper}>
                                <Box className={'PADmainWrapper'}>
                                    <Box className={'PADContentWrapper'}>
                                        <Typography className="fs14 fw700 maxWidth70p">{`${actionData?.actionIndex} ${selectedAction?.name}, ${selectedAction?.projectName}`}</Typography>
                                        <StatusIndicator 
                                        status={selectedAction?.status ? selectedAction?.status : 0}
                                        reportType={'rabg'}
                                        />
                                    </Box>
                                    <Box className={'PADContentWrapper'}>
                                        <Box className={'PADContentTitleDesc'}>
                                            <TextFieldWithoutBorders
                                            value={selectedAction?.name ? selectedAction?.name : ''}
                                            handleChange={(e:any) => {
                                                const tempObj = {name: e.target.value};
                                                handleChangeState(tempObj);
                                            }}
                                            placeholder={t('ENTER_ACTION_NAME')}
                                            />
                                            <TextAreaWithoutBorders
                                            value={selectedAction?.description ? selectedAction?.description : ''}
                                            handleChange={(e:any) => {
                                                const tempObj = {description: e.target.value};
                                                handleChangeState(tempObj);
                                            }}
                                            placeholder={t('ENTER_ACTION_DESCRIPTION')}
                                            />
                                        </Box>
                                        <Box className={'PDAselectBoxWrapper'}>
                                            <StatusSelectBoxDefault value={selectedAction?.status} manageSelect={handleChangeState} />
                                            <OwnerSelectBox value={selectedAction?.ownerUserId} manageSelect={handleChangeState} />
                                            <PerfActionDateSelector value={selectedAction?.dueDate} manageSelect={handleChangeState}/>
                                            <DepartmentSelectBox value={selectedAction?.departmentId} manageSelect={handleChangeState} />
                                        </Box>
                                    </Box>
                                    <Box className={'mt30'}>
                                        <Typography className="fs13 fw700 uppercase">{t('TASKS')}</Typography>
                                        <ProgressBar percent={selectedAction?.tasks ? calcTaskPercentage(selectedAction?.tasks) : 0} width="100%"/>
                                        <TasksTable data={selectedAction?.tasks ? selectedAction?.tasks : []} 
                                        isLoading={isFetchingAction}
                                        handleChange={handleChangeTask}/>
                                    </Box>
                                    <Box className={'mt30'}>
                                        <Typography className="fs13 fw700 uppercase">{t('EVALUATION_STANDARDS')}</Typography>
                                        <PerfActionStandardsTable buttonLabel={t('LINK_EVALUATION_STANDARDS')} 
                                        data={selectedAction?.standards ? selectedAction?.standards : []}
                                        manageLinkStandard={handleChangeStandards} />
                                    </Box>
                                    <Box className={'mt30'}>
                                        <Typography className="fs13 fw700 uppercase">{t('LICENCE_STANDARDS')}</Typography>
                                        <PerfActionLicenceStandardsTable buttonLabel={t('LINK_LICENCE_STANDARDS')}
                                         data={selectedAction?.licenceStandards ? selectedAction?.licenceStandards : []} 
                                         manageLinkStandard={handleChangeLicenceStandards}/>
                                    </Box>
                                    <Box className={'mt30'}>
                                        <Typography className="fs13 fw700 uppercase">{t('COMMENTS')}</Typography>
                                        <Box sx={{ display: "flex" }}>
                                            <Box sx={{ width: '30%' }}>
                                                <CommentsOverviewBox manageClick={() => {}} parentComponent={'jlEval'} />
                                            </Box>
                                            <Box sx={{ width: '70%' }}>
                                                <ReportComment />
                                            </Box>
                                        </Box>
                                    </Box>
                                </Box>
                            </Paper>
                        </Fade>
                    </ClickAwayListener>)}
            </Popper>
        </React.Fragment>
    )
}

export default PerformanceActionDetailPopper;