import React from 'react';
import './styles.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faComments, faFileInvoice, faPersonRunning } from '@fortawesome/free-solid-svg-icons';
import { Typography } from '@mui/material';

const CommentsDocsActionsIconWrapper = (props: any) => {
    const { actions, docs, comments } = props;
    return(
        <div>
            {
                (comments != undefined && comments != null) &&
                <span>
                    <Typography className='countLabel fs12'>{comments ? comments : 0}</Typography>
                    <FontAwesomeIcon className='triIcons icon' icon={faComments} />
                </span>
            }
            {
                (docs != undefined && docs != null) &&
                <span>
                    <Typography className='countLabel fs12'>{docs ? docs : 0}</Typography>
                    <FontAwesomeIcon className='triIcons icon' icon={faFileInvoice} />
                </span>
            }
            {
                (actions != undefined && actions != null) &&
                <span>
                    <Typography className='countLabel fs12'>{actions ? actions : 0}</Typography>
                    <FontAwesomeIcon className='triIcons icon' icon={faPersonRunning} />
                </span>
            }
        </div>
    )
}

export default CommentsDocsActionsIconWrapper;