import axios from "axios";
import { encodeBody, encodeQueryParams } from "./client";
import { baseUrl } from "./config";

export const getAllActions: any = (params:any) => {
    const path = `${baseUrl}/api/actions${encodeQueryParams(params)}`;
    return axios({
      method: 'GET',
      url: path,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    }).then(response => response.data.data).catch((err) => console.error('Error in getAllActions:', err));
  };
  
export const updateActions: any = (parms:any) => {
  const path = `${baseUrl}/api/actions/clubs/${parms.clubId}/standards/${parms.standardId}`;
  return axios({
    method: 'POST',
    url: path,
    data: encodeBody(parms.actions),
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  }).then(response => response.data.data).catch((err) => console.error('Error in updateActions:', err));
};

export const getAction: any = (params:any) => {
  const path = `${baseUrl}/api/actions/${params.id}${encodeQueryParams({ pastStatusPeriod: params.pastStatusPeriod })}`;
  return axios({
    method: 'GET',
    url: path,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  }).then(response => response.data.data).catch((err) => console.error('Error in getAction:', err));
};

export const updateAction: any = (params:any) => {
  const path = `${baseUrl}/api/actions/${params.actionId}`;
  return axios({
    method: 'PUT',
    url: path,
    data: encodeBody(params.action),
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  }).then(response => response.data.data).catch((err) => console.error('Error in updateAction:', err));
};

export const deleteAction: any = (params:any) => {
  const path = `${baseUrl}/api/actions/${params.actionId}`;
  return axios({
    method: 'DELETE',
    url: path,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  }).then(response => response.data.data);
};

export const archiveAction: any = (params:any) => {
  const path = `${baseUrl}/api/actions/archive/${params.actionId}`;
  return axios({
    method: 'POST',
    url: path,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  }).then(response => response.data.data);
};

export const createAction: any = (params:any) => {
  const path = `${baseUrl}/api/actions/single`;
  return axios({
    method: 'POST',
    url: path,
    data: encodeBody(params.action),
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  }).then(response => response.data.data);
};