import React from 'react';
import { useEffect } from "react";
import './styles.css';
import Chart from 'react-apexcharts';
import { colors } from "../../../common/pallette/colors";
import { Box, Typography } from "@mui/material";

const StackedHorizontalBar = (props: {data: any, label: string, colorPalette: any}) => {

  const { data, label, colorPalette } = props;
  
  const optionsBar = {
    chart: {
      stacked: true,
      // stackType: "100%",
      parentHeightOffset: 0,
      toolbar: {
        show: false
      },
      sparkline: {
        enabled: true
      }
    },
    plotOptions: {
      bar: {
        horizontal: true,
        // columnWidth: '100%'
      },
    },
    dataLabels: {
      enabled: true,
      dropShadow: {
        enabled: false
      },
      offsetX: 0
    },
    stroke: {
      width: 0,
    },
    yaxis: {
      labels: {
        offsetX: 0,
        show: false
      }
    },
    xaxis: {
      categories: [label],
      labels: {
        show: false,
      },
      axisBorder: {
        show: false
      },
      axisTicks: {
        show: false
      }
    },
    legend: {
      show: false
    },
    colors: colorPalette
  }

  // useEffect(() => {
  //   // setTimeout(() => {
  //     var chart = new ApexCharts(document.querySelector("#chart"), options);
  //     chart.render();
  //   // }, 3000)
  // }, [options]);  

  return(
    <Box sx={{ display: "flex" }}>
      <Typography className="mtbAuto mr5 fs13 fw800">{label}</Typography>
      {
        (data && data?.length) &&
        <Box className="mtbAuto">
          <Chart
            options={optionsBar}
            height={42}
            series={data}
            type="bar"
            width={400}
          />
        </Box>
      }
    </Box>
  )
}

export default StackedHorizontalBar;