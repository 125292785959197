import React, { useEffect, useState } from 'react';
import './styles.css';
//internal or external components import
import { Box, AccordionSummary, Typography, AccordionDetails, ThemeProvider, createTheme, Tab, Button } from '@mui/material';
import SearchBox from '../../components/SearchBox';
import SortBox from '../../components/SortBox';
import { SwitchButton } from '../../components/SwitchButton';
import { colors } from '../../common/pallette/colors';
import TextCard from '../../components/TextCard';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion } from '../../common/AccordionsCustomized';
import ClubCriteria from '../ClubCriteria';
import { clubCriteria, comments } from '../../assets/staticData';
import { getClubReviewsInfo, getCriteriasSelfAssessmentComments, getEvaluationCommentsByCriteria, getOffSiteEvaluation, getOnSiteQuestions } from '../../api/evaluation';
import { useDispatch, useSelector } from 'react-redux';
import { setCommentContent, setEvalCriteriaComments, setOffSiteCriteriaComments, setOnSiteCriteriaComments, setSaCriteriaComments, setSelectedCommentPhase } from '../../redux/reducers/evaluation';
import { useTranslation } from 'react-i18next';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import ChatsContactCard from '../../components/ChatsContactCard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';

export const CommentsOverviewBox = (props: any) => {
    const { manageClick, parentComponent } = props;
    const currentCriteria = useSelector((state:any) => state.evaluation.selectedSaCriteria);
    const currentSection = useSelector((state:any) => state.evaluation.currentEvaluationTab);
    const clubDetails = useSelector((state:any) => state.clubDetails);
    const onSiteCriteriaComments = useSelector((state:any) => state.evaluation.onSiteCriteriaComments);
    const criteriaComments = useSelector((state:any) => state.evaluation.criteriaComment);
    const saStandards = useSelector((state:any) => state.evaluation.saStandards);
    const selectedOnSiteCriteria = useSelector((state:any) => state.evaluation.selectedOnSiteCriteria);
    const dispatch = useDispatch();
    const [t] = useTranslation();
    const tabs = ['CRITERIA_LEVEL_COMMENTS', 'CHATS'];
    const [selectedTab, setSelectedTab] = useState('CRITERIA_LEVEL_COMMENTS');
    const [expanded, setExpanded] = React.useState<string | false>(currentSection || '');
    
    useEffect(() => {
        const fetchSaComments = async () => {
            const response = await getCriteriasSelfAssessmentComments({ clubId: clubDetails.id, standardId: 0, criteriaId: currentCriteria.id });
            return response;
        };
        const fetchOffSiteComments = async () => {
            const response = await getOffSiteEvaluation({ clubId: clubDetails.id, standardId: 0, criteriaId: currentCriteria.id });
            return response;
        };
        const fetchOnSiteComments = async () => {
            const reviewData = await getClubReviewsInfo({clubId: clubDetails.id});
            if(reviewData && reviewData.onSiteReviewId){
                const response = await getOnSiteQuestions({onSiteReviewId: reviewData.onSiteReviewId, sessionId: 0, criteriaId: currentCriteria.id});
                return response;
            }
        };
        const fetchEvaluationComments = async () => {
            const response = await getEvaluationCommentsByCriteria({ clubId: clubDetails.id, criteriaId: currentCriteria.id });
            return response;
        };
        if (currentCriteria && currentCriteria.id && clubDetails && clubDetails.id) {
            fetchSaComments().then((res:any) => {
                var tempArr = res.filter((x:any) => x.comment != null && x.comment != '');
                dispatch(setSaCriteriaComments(tempArr));
            });
            fetchOffSiteComments().then((res:any) => {
                var tempArr = res.filter((x:any) => x.comment != null && x.comment != '');
                dispatch(setOffSiteCriteriaComments(tempArr));
            });
            fetchEvaluationComments().then((res:any) => {
                var tempArr = res.filter((x:any) => x.comment != null && x.comment != '');
                dispatch(setEvalCriteriaComments(tempArr));
            });
            //commented due to development of new criteria level comments / fetching the onsite criteria from different area
            // fetchOnSiteComments().then((res:any) => {
            //     var tempArr = res.answers.filter((x:any) => x.comment != null && x.comment != '');
            //     dispatch(setOnSiteCriteriaComments(tempArr));
            // }); 
        }
    }, [currentCriteria]);

    const handleChange =
        (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
        setExpanded(newExpanded ? panel : false);
        };

    const theme = createTheme({
        components: {
            MuiAccordionSummary: {
                styleOverrides: {
                    root: {
                        padding: 0,
                    },
                    content: {
                        margin: 0
                    }
                }
            },
            MuiAccordion: {
                styleOverrides:{
                    root: {
                        backgroundColor: 'transparent'
                    }
                }
            },
            MuiAccordionDetails: {
                styleOverrides: {
                    root: {
                        padding: 0
                    }
                }
            }
        }
    })

    const manageCommentCardClick = (obj:any) => {
        dispatch(setCommentContent(obj));
    }

    const handleTabChange = (event: React.SyntheticEvent, newValue: any) => {
        setSelectedTab(newValue);
    };

    return(
        <React.Fragment>
            <ThemeProvider theme={theme}>
                <Box className={'commentsOverviewWrapper'} sx={{ bgcolor: colors.grey.bright }}>
                    <TabContext value={selectedTab}>
                        <Box>
                            <SearchBox searchBoxStyle={'cobSearchBoxStyle'} />
                            <TabList onChange={handleTabChange}>
                                {
                                    tabs.map((tabName: string, index: number) => {
                                        return <Tab label={tabName} value={tabName} />;
                                    })
                                }
                            </TabList>
                        </Box>
                        <Box sx={{ maxHeight: '70%', overflowY: 'auto', overflowX: 'hidden', mt: 1 }}>
                            {
                                parentComponent == 'jlEval' ?
                                <>
                                <TabPanel value='CRITERIA_LEVEL_COMMENTS' className='tabPanelComments'>
                                <>
                                    <Accordion expanded={expanded === t('EVALUATION')} onChange={handleChange(t('EVALUATION'))} elevation={0}>
                                        <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                        >
                                        <Typography className={'fw800 uppercase'}>{t('OFF_SITE')}</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails sx={expanded !== t('EVALUATION') ? { display: 'none' } : {}}>
                                            {
                                                true ?
                                                <TextCard data={{
                                                    comment: criteriaComments?.offSiteComment,
                                                    updatedDate: criteriaComments?.offSiteUpdatedDate,
                                                    avatarPath: criteriaComments?.offSiteUserAvatarPath,
                                                    firstName: criteriaComments?.offSiteUpdatedUserFirstName,
                                                    lastName: criteriaComments?.offSiteUpdatedUserLastName,
                                                }} from={t('OFF_SITE')}/>:
                                                <Typography className='fs12'>{t('NO_RECORDS_FOUND')}</Typography>
                                            }
                                        </AccordionDetails>
                                    </Accordion>
                                    <Accordion expanded={expanded === t('ON_SITE')} onChange={handleChange(t('ON_SITE'))} elevation={0}>
                                        <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel2a-content"
                                        id="panel2a-header"
                                        >
                                        <Typography className={'fw800 uppercase'}>{t('ON_SITE')}</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails sx={expanded !== t('ON_SITE') ? { display: 'none' } : {}}>
                                            {
                                                onSiteCriteriaComments && onSiteCriteriaComments?.length ?
                                                onSiteCriteriaComments.map((d: any) => {
                                                    return <TextCard data={d} from={t('ON_SITE')}/>
                                                }):
                                                <Typography className='fs12'>{t('NO_RECORDS_FOUND')}</Typography>
                                            }
                                        </AccordionDetails>
                                    </Accordion>
                                    <Accordion expanded={expanded === t('INTERIM_REPORT')} onChange={handleChange(t('INTERIM_REPORT'))} elevation={0}>
                                        <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel3a-content"
                                        id="panel3a-header"
                                        >
                                        <Typography className={'fw800 uppercase'}>{t('INTERIM_REPORT')}</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails sx={expanded !== t('INTERIM_REPORT') ? { display: 'none' } : {}}>
                                            {
                                                true ?
                                                <TextCard data={{
                                                    comment: criteriaComments?.interimReportComment,
                                                    updatedDate: criteriaComments?.interimReportUpdatedDate,
                                                    avatarPath: criteriaComments?.interimReportUserAvatarPath,
                                                    firstName: criteriaComments?.interimReportUpdatedUserFirstName,
                                                    lastName: criteriaComments?.interimReportUpdatedUserLastName,
                                                }}  from={t('INTERIM_REPORT')}/>:
                                                <Typography className='fs12'>{t('NO_RECORDS_FOUND')}</Typography>
                                            }
                                        </AccordionDetails>
                                    </Accordion>
                                    <Accordion expanded={expanded === t('FINAL_REPORT')} onChange={handleChange(t('FINAL_REPORT'))} elevation={0}>
                                        <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel4a-content"
                                        id="panel4a-header"
                                        >
                                        <Typography className={'fw800 uppercase'}>{t('FINAL_REPORT')}</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails sx={expanded !== t('FINAL_REPORT') ? { display: 'none' } : {}}>
                                            {
                                                true ?
                                                <TextCard data={{
                                                    comment: criteriaComments?.finalReportComment,
                                                    updatedDate: criteriaComments?.finalReportUpdatedDate,
                                                    avatarPath: criteriaComments?.finalReportUserAvatarPath,
                                                    firstName: criteriaComments?.finalReportUpdatedUserFirstName,
                                                    lastName: criteriaComments?.finalReportUpdatedUserLastName,
                                                }}  from={t('FINAL_REPORT')}/>:
                                                <Typography className='fs12'>{t('NO_RECORDS_FOUND')}</Typography>
                                            }
                                        </AccordionDetails>
                                    </Accordion>
                                </>
                                </TabPanel>
                                <TabPanel value='CHATS' className='tabPanelComments'>
                                <>
                                    <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')} elevation={0}>
                                        <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                        >
                                        <Typography className={'fw800 uppercase'}>{t('SELF_ASSESSMENT')}</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails sx={expanded !== 'panel1' ? { display: 'none' } : {}}>
                                            {
                                                saStandards && saStandards.length ?
                                                saStandards.map((d: any, i:any) => {
                                                    return <ChatsContactCard data={d} handleClick={manageClick} from={'SELF_ASSESSMENT'}/>
                                                }):
                                                <Typography className='fs12'>{t('NO_RECORDS_FOUND')}</Typography>
                                            }
                                        </AccordionDetails>
                                    </Accordion>
                                    <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')} elevation={0}>
                                        <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel2a-content"
                                        id="panel2a-header"
                                        >
                                        <Typography className={'fw800 uppercase'}>{t('OFF_SITE')}</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails sx={expanded !== 'panel2' ? { display: 'none' } : {}}>
                                            {
                                                saStandards && saStandards.length ?
                                                saStandards.map((d: any, i:any) => {
                                                    return <ChatsContactCard data={d} handleClick={manageClick} from={'OFF_SITE'}/>
                                                }):
                                                <Typography className='fs12'>{t('NO_RECORDS_FOUND')}</Typography>
                                            }
                                        </AccordionDetails>
                                    </Accordion>
                                    <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')} elevation={0}>
                                        <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel4a-content"
                                        id="panel4a-header"
                                        >
                                        <Typography className={'fw800 uppercase'}>{t('EVALUATION')}</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails sx={expanded !== 'panel4' ? { display: 'none' } : {}}>
                                            {
                                                saStandards && saStandards.length ?
                                                saStandards.map((d: any, i:any) => {
                                                    return <ChatsContactCard data={d} handleClick={manageClick} from={'EVALUATION'}/>
                                                }):
                                                <Typography className='fs12'>{t('NO_RECORDS_FOUND')}</Typography>
                                            }
                                        </AccordionDetails>
                                    </Accordion>
                                </>
                                </TabPanel>
                                </>
                            :
                            <>
                            {/* {
                                clubCriteria.map((criteria,i) => {
                                    return<Accordion expanded={expanded === 'panel'+i} onChange={handleChange('panel'+i)} elevation={0}>
                                        <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls={"panel"+i+"a-content"}
                                        id={"panel"+i+"a-header"}
                                        >
                                        <Typography className={'fw800'}>{criteria.id} {criteria.criteria}</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails sx={expanded !== 'panel'+i ? { display: 'none' } : {}}>
                                            {
                                                (data.filter((d: any) => d.criteriaId === criteria.id)).map((d: any) => {
                                                    return <TextCard data={d} handleClick={manageClick}/>
                                                })
                                            }
                                        </AccordionDetails>
                                    </Accordion>
                                })
                            } */}
                            </>
                            }
                        </Box>
                    </TabContext>
                </Box>
            </ThemeProvider>
        </React.Fragment>
    )
}