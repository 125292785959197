import React from "react";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import { styled } from "@mui/material/styles";
import { colors } from "../../common/pallette/colors";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleXmark } from "@fortawesome/free-regular-svg-icons";

const AlwaysFocusedTextArea = styled(TextField)(({ theme }) => ({
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: colors.black.dark,
    },
    "&:hover fieldset": {
      borderColor: colors.black.dark,
    },
    "&.Mui-focused fieldset": {
      borderColor: colors.black.dark,
    },
  },
  "& .MuiOutlinedInput-input": {
    "&:focus": {
      outline: "none",
    },
    color: colors.black.dark,
  },
  "& .MuiFormLabel-root": {
    color: colors.black.dark,
  },
  "& .MuiInputBase-input": {
    padding: "10px",
  },
  "& .MuiOutlinedInput-multiline": {
    padding: "10px", // To ensure padding is consistent with multiline input
  },
}));

export default function TextAreaDefault(props: any) {
  const { label, handleChange, value, rows } = props;

  return (
    <AlwaysFocusedTextArea
      variant="outlined"
      value={value}
      onChange={handleChange}
      multiline
      rows={rows || 4} // Default rows set to 4
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton edge="end">
              <FontAwesomeIcon
                color={colors.black.dark}
                className="fs14"
                icon={faCircleXmark}
              />
            </IconButton>
          </InputAdornment>
        ),
      }}
      focused
      fullWidth
      label={label}
    />
  );
}
