import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from "react-redux";
//internal and external components import
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import { TableRow, Popper, Fade, Paper, Typography, Collapse, IconButton } from '@mui/material/';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown, faArrowRight, faChevronDown, faChevronRight, faStar } from '@fortawesome/free-solid-svg-icons';
import { Box } from '@mui/material';
import { EnhancedTableHead } from '../../components/EnhancedTableHead';
import { genUID } from '../../common/StaticExports';
import TableSkeleton from '../../components/TableSkeleton';
import CommentsDocsActionsIconWrapper from '../../components/CommentsDocsActionsIconWrapper';
import SelectBoxPrimary from '../../components/SelectBoxPrimary';
import StatusIndicatorSmall from '../../components/StatusIndicatorSmall';
import { colors } from '../../common/pallette/colors';
import { fetchCriteriaSesions, getClubReviewsInfo, getOnSiteCriteriaComments, getOnSiteCriteriaStandards, getOnSiteQuestions, syncQuestions } from '../../api/evaluation';
import { setCommentContent, setInterviewDetails, setOnSiteCriteriaComments, setOnSiteStandards, setSelectedOnSiteCriteria } from '../../redux/reducers/evaluation';
import { useTranslation } from 'react-i18next';
import SAStandardsDetail from '../StandardsDetail/SAStandardsDetail';
import InterviewStandardDetails from '../StandardsDetail/InterviewStandardDetails';

const InterviewStandards = () => {
  const dispatch = useDispatch();
  const standards = useSelector((state:any) => state.evaluation.onSiteStandards);
  const interviewDetails = useSelector((state:any) => state.evaluation.interviewDetails);
  const currentClub = useSelector((state:any) => state.clubDetails);
  const selectedOnSiteSession = useSelector((state:any) => state.evaluation.selectedOnSiteSession);
  const selectedOnSiteCriteria = useSelector((state:any) => state.evaluation.selectedOnSiteCriteria);
  const [isOpen, setIsOpen] = React.useState(999);
  const [isFetching, setIsFetching] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [t] = useTranslation();
  const [openStandard, setOpenStandard] = React.useState(999);
  const [detail, setDetail] = React.useState(Object);

  //supporting contents
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('uid');

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: any,
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };
  
//   let visibleRows = React.useMemo(
//     () =>
//       standards && standards.length > 0 &&
//       sortData(standards, orderBy, order),
//     [order, orderBy, standards],
//   );

  const head = [
    {
      key: 'uid', displayName: 'id',
    },
    {
      key: 'name', displayName: 'criteria/standards',
    },
    {
      key: 'offSiteStatus', displayName: 'Off-Site',
    },
    {
      key: 'star', displayName: 'star',
    },
    {
      key: '', displayName: '',
    },
    {
      key: '', displayName: 'rating',
    },
    {
      key: '', displayName: '',
    },
  ]

  useEffect(() => {
      const fetchInterviewDetails = async () => {
        setIsLoading(true);
          const reviewData = await getClubReviewsInfo({clubId: currentClub?.id});
          const response = await getOnSiteCriteriaStandards({onSiteReviewId: reviewData.onSiteReviewId, sessionId: selectedOnSiteSession?.id});
          dispatch(setInterviewDetails(response));
          setIsLoading(false);
      }
      (selectedOnSiteSession?.id && currentClub?.id) &&
      fetchInterviewDetails();
  }, [selectedOnSiteSession, currentClub]);

  const fetchStandards = async (criteria:any) => {
    setIsFetching(true);
    const reviewData = await getClubReviewsInfo({clubId: currentClub?.id});
    const response = await getOnSiteQuestions({onSiteReviewId: reviewData?.onSiteReviewId, sessionId: selectedOnSiteSession?.id, criteriaId: criteria?.id});
    dispatch(setOnSiteStandards(response?.answers));
    setIsFetching(false);
  }

  const fetchOnSiteCriteriaComments = async (data:any) => {
    if(data && data?.id && currentClub && currentClub?.id) {
      const criteriaSessions = await fetchCriteriaSesions({clubId: currentClub.id, criteriaId: data.id});
      const response = await getOnSiteCriteriaComments({clubId: currentClub?.id, criteriaId: data?.id});
      const updatedResponse = criteriaSessions.map((item:any) => {
        const tempD = response.find((d:any) => (d.sessionId == item.id));
        if(tempD) {
          return tempD;
        } else {
          return {
            "id": null,
            "comment": null,
            "criteriaId": data.id,
            "sessionId": item.id,
            "clubId": currentClub?.id
        }
        }
      })
      dispatch(setOnSiteCriteriaComments(updatedResponse || []));
      if(updatedResponse) {
        const tempObj = updatedResponse.find((item:any) => (item.criteriaId  == data?.id && item.sessionId == selectedOnSiteSession.id));
        dispatch(setCommentContent(tempObj));
      }
    }
  }

  const updateStatus = async (value:any, standard:any) => {
    if (standards) {
      const tempArr = standards?.map((item:any) => {
        if(item.id == standard.id) {
          return {...standard, status: value};
        } return item;
      });
      const reviewData = await getClubReviewsInfo({clubId: currentClub?.id});
      const response = await syncQuestions({onSiteReviewId: reviewData?.onSiteReviewId, sessionId: selectedOnSiteSession?.id, questions: tempArr});
      response && dispatch(setOnSiteStandards(tempArr));
    }
  }

  //table contents
    return(
        <React.Fragment>
            <Box sx={{ position: 'relative' }}>
            {/* umcomment the below line to enable open popup */}
            {/* <SAStandardsDetail isOpen={isOpenStandard} detail={detail} handleClose={() => setIsOpenStandard(false)}/> */}
              <TableContainer sx={{ height: '40vh', overflowY: 'auto' }}>
                <Table stickyHeader size="small" aria-label="a dense table">
                    <EnhancedTableHead
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={handleRequestSort}
                    tableHeads={head}
                    bg={colors.white.bright}
                    />
                    {
                      !isLoading && interviewDetails.length ?
                      <TableBody>
                        {
                          interviewDetails.map((d: any, i: number) => (
                            <>
                              <TableRow
                              className={selectedOnSiteCriteria?.id == d.id ? 'selectedRow' : 'hoverBG'}
                              sx={{ cursor: 'pointer' }}
                              key={`interview-criteria-table-row-${i}`}
                              onClick={(e) => {
                                e.stopPropagation();
                                dispatch(setSelectedOnSiteCriteria(d));
                                let num = i == isOpen ? 999 : i;
                                setIsOpen(num);
                                if (i != isOpen && selectedOnSiteSession?.id && d?.id &&
                                  selectedOnSiteCriteria.id !== d.id) {
                                  fetchOnSiteCriteriaComments(d);
                                  fetchStandards(d);
                                }
                              }}
                              >
                                <TableCell className='fs12' sx={{ width: '35px' }}>{genUID(d.uid)}</TableCell>
                                <TableCell className='fs12' sx={{ width: '510px' }}>{d.name}</TableCell>
                                <TableCell className='fs12' sx={{ width: '310px' }}>
                                    <StatusIndicatorSmall reportType={'siteReview'} status={d.offSiteStatus} />
                                </TableCell>
                                <TableCell className='fs12' sx={{ width: '100px' }}>
                                </TableCell>
                                <TableCell className='fs12' sx={{ width: '180px' }}>
                                </TableCell>
                                <TableCell className='fs12' sx={{ width: '100px' }}>
                                  <StatusIndicatorSmall reportType={'siteReview'} status={d.onSiteStatus} />
                                </TableCell>
                                <TableCell sx={{ width: '40px' }}>
                                    {i == isOpen ? <FontAwesomeIcon className='icon' icon={faChevronDown} /> : <FontAwesomeIcon className='icon' icon={faChevronRight} />}
                                </TableCell>
                              </TableRow>
                              {/* collapsible row */}
                              <TableRow className={isOpen !== i ? 'displayNone':''}>
                                <TableCell colSpan={7}>
                                  <Collapse in={isOpen == i}>
                                  {
                                    !isFetching && i == isOpen && standards && standards.length ?
                                    standards.map((standard:any, j:any) => {
                                        return (
                                          <>
                                          <TableRow
                                          className='hoverBG'
                                          sx={{ cursor: 'pointer' }}
                                          key={`interview-standards-table-row-${i}`}
                                          onClick={() => {
                                            let num = j == openStandard ? 999 : j;
                                            if (num == 999) {
                                              setDetail(Object);
                                            } else {
                                              setDetail({id: standard?.standardId, uid: standard?.standardUid, name: standard?.standardName });
                                            }
                                            setOpenStandard(num);
                                          }} 
                                          >
                                              <TableCell className='fs12'>{genUID(standard.standardUid)}</TableCell>
                                              <TableCell className='fs12' sx={{ width: '586px' }}>{standard.standardName}</TableCell>
                                              <TableCell className='fs12' sx={{ width: '360px' }}>
                                                  <StatusIndicatorSmall reportType={'siteReview'} status={standard.offSiteStatus} />
                                              </TableCell>
                                              <TableCell className='fs12' sx={{ width: '100px' }}>
                                                  {
                                                      Array.from({length: standard.standardStarRating}, () => <FontAwesomeIcon icon={faStar} style={{color: colors.yellow.bright,}} />)
                                                  }
                                              </TableCell>
                                              <TableCell className='fs12'  sx={{ width: '180px' }}><CommentsDocsActionsIconWrapper actions={standard.noOfActions} comments={standard.noOfComments} docs={standard.noOfEvidences} /></TableCell>
                                              <TableCell className='fs12'  sx={{ width: '160px' }} onClick={(e) => {e.stopPropagation()}}>
                                                  <SelectBoxPrimary 
                                                  label={''} 
                                                  report={'siteReview'} 
                                                  options={[0, 1, 2, 3]} 
                                                  isStatus={true} 
                                                  manageChange={(e:any) => {updateStatus(e, standard)}}
                                                  selected={standard.status} />
                                              </TableCell>
                                              <TableCell sx={{ width: '40px' }}>
                                                  {/* {i == isOpen ? <FontAwesomeIcon className='icon' icon={faChevronDown} /> : <FontAwesomeIcon className='icon' icon={faChevronRight} />} */}
                                              </TableCell>
                                          </TableRow>
                                          {/* collapsible row for standard details*/}
                                          <TableRow className={(openStandard !== j) ? 'displayNone':''}>
                                            <TableCell colSpan={7}>
                                              <Collapse in={isOpen == i && openStandard == j}>
                                                <InterviewStandardDetails detail={detail}/>
                                              </Collapse>
                                            </TableCell>
                                          </TableRow>
                                          </>
                                        )
                                    }) : 
                                    <TableSkeleton rowCount={4} cellCount={7} width={800} />
                                  }
                                  </Collapse>
                                 </TableCell>
                               </TableRow>
                            </>
                          ))
                        }
                      </TableBody>
                      :
                      (!isLoading && interviewDetails.length == 0) ?
                      <TableBody>
                        <TableRow>
                          <TableCell colSpan={7} className='fs13' sx={{ textAlign: 'center' }} >{t('NO_RECORDS_FOUND')}</TableCell>
                        </TableRow>
                      </TableBody> :
                      <TableBody>
                        <TableSkeleton rowCount={8} cellCount={6} />
                      </TableBody>
                    }
                </Table>
              </TableContainer>
            </Box>
        </React.Fragment>
    )
}

export default InterviewStandards;