import React from "react";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import { styled } from "@mui/material/styles";
import { colors } from "../../common/pallette/colors";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleXmark } from "@fortawesome/free-regular-svg-icons";

const AlwaysFocusedTextField = styled(TextField)(({ theme }) => ({
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: colors.black.dark,
    },
    "&:hover fieldset": {
      borderColor: colors.black.dark,
    },
    "&.Mui-focused fieldset": {
      borderColor: colors.black.dark,
    },
  },
  "& .MuiOutlinedInput-input": {
    "&:focus": {
      outline: "none",
    },
    "& .MuiFormLabel-root": {
      color: colors.black.dark,
    },
  },
  "& .MuiFormLabel-root": {
    color: colors.black.dark
  },
  "& .MuiInputBase-input": {
    padding: "10px"
  },
  "& .MuiInputLabel-root": {
    "&:focus": {
      color: colors.black.dark,
    }
  }
}));

export default function TextFieldDefault(props:any) {
    const { label, handleChange, value } = props;

  return (
    <AlwaysFocusedTextField
      variant="outlined"
      value={value}
      onChange={handleChange}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton edge="end">
                <FontAwesomeIcon color={colors.black.dark} className="fs14" icon={faCircleXmark} />
            </IconButton>
          </InputAdornment>
        ),
      }}
      focused
      fullWidth
      label={label}
    />
  );
}
