import { faPencil, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, ClickAwayListener, Tooltip, Typography } from "@mui/material";
import React, { useState } from "react";
import TableSingleSelectBox from "../SearchSelectBox/TableSingleSelectBox";
import { useDispatch, useSelector } from "react-redux";
import { deleteInterviewer, getClubReviewsInfo, syncInterviewers } from "../../api/evaluation";
import { setInterviewers } from "../../redux/reducers/evaluation";
import InterviewTeamSelectBox from "../InterviewTeamSelectBox";

const DataPreview = (props:any) => {
    const { label, data } = props;
    const [isAdd, setIsAdd] = useState(false);
    const interviewTeams = useSelector((state:any) => state.evaluation.interviewTeams);
    const clubDetails = useSelector((state:any) => state.clubDetails);
    const dispatch = useDispatch();

    const updateInterviewUsers = async (updatedUser:any, currentData:any) => {
      if (!updatedUser) {
        if (currentData && currentData?.id) {
          const response = await deleteInterviewer(currentData?.id);
          const tempArr = data.filter((item:any) => currentData.id != item.id);
          dispatch(setInterviewers(tempArr));
        }
      } else {
        const reviewData = await getClubReviewsInfo({clubId: clubDetails?.id});
        const response = await syncInterviewers({ onSiteReviewId: reviewData?.onSiteReviewId, user: updatedUser });
        const sortedInterviewers = response.map((item:any) => {
          if (item.displayName == null) {
            const tempObj = interviewTeams.find((d:any) => d.id == item.userId);
            if (tempObj) {
              return {...item, displayName: tempObj.displayName, roleName: tempObj.roleName};
            } else {
              return {...item, displayName: 'No Name', roleName: 'No Roles'};
            }
          } return {...item, roleName: ''};
        })
        dispatch(setInterviewers(sortedInterviewers));
      }
    }

    const manageNullData = () => {
      if(isAdd) {
        const tempArr = data.filter((item:any) => !(item.displayName == null && item.userId == null));
        dispatch(setInterviewers(tempArr));
      }
    }

    return (
      <ClickAwayListener onClickAway={() => {manageNullData(); setIsAdd(false);}}>
        <Box sx={{ display: "flex" }}>
          <Typography className="mtbAuto mr5 fs13 fw800">{label}</Typography>
          <Box sx={{ display: 'grid', gridTemplateColumns: 'auto auto', gridTemplateRows: 'auto auto' }}>
            {
                data && data.map((d:any) => {
                  if(isAdd) {
                    return(
                      <Box sx={{ width: '180px' }}>
                      <InterviewTeamSelectBox
                      value={d}
                      manageSelect={(e:any) => {updateInterviewUsers(e, d)}}
                      />
                      </Box>
                    )
                  } else {
                    return <Typography className="mtbAuto fs13 ml10">{`${d?.displayName}, ${d?.roleName ? d?.roleName : '-'}`}</Typography>
                  }
                })
            }
          </Box>
          <Tooltip title={'Add Interview Team'} arrow placement="top">
            <FontAwesomeIcon className={'icon mtbAuto ml10'}
             onClick={() => {
              const tempObj = {
                displayName: null,
                userId: null,
                id: null,
              };
              const tempArr:any = [...data, tempObj];
              dispatch(setInterviewers(tempArr));
              setIsAdd(true);
              }} icon={faPlus} />
          </Tooltip>
        </Box>
      </ClickAwayListener>
    )
}

export default DataPreview;