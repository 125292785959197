import React, { useEffect, useState } from "react";
import { getAllActions } from "../../api/action";
import { useSelector } from "react-redux";
import SingleSelectDefault from "../SingleSelectDefault";
import { useTranslation } from "react-i18next";
import TextFieldDefault from "../TextFieldDefault";

export const ActionTextBoxDefault = (props:any) => {
    const { value, label, handleChange } = props;

    const manageChange = (e:any) => {
        const tempObj = { name: e.target.value };
        handleChange(tempObj);
    }

    return(
        <TextFieldDefault
        label={label} 
        handleChange={manageChange}
        value={value}/>
    )
}