import React from 'react';
//internal or external components and containers
import { Box, Typography, Avatar } from "@mui/material";
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { colors } from '../../common/pallette/colors';
import { useTranslation } from 'react-i18next';
import { report } from 'process';
import { commonStatus, finalReportStatus, interimReportStatus, liecenceAdmin, liecenceHome, rabgStatus, siteReviewStatus } from '../../common/StaticExports';

const displayStatus = (key: String, status: number) => {
    switch (key) {
        case 'interim':
            return interimReportStatus[status];

        case 'final':
            return finalReportStatus[status];
            
        case 'rabg':
            return rabgStatus[status];
        
        case 'siteReview':
            return siteReviewStatus[status];

        case 'liecenceHome':
            return liecenceHome[status];
            
        case 'liecenceAdminAction':
            return liecenceAdmin[status];
    
        default:
            return commonStatus[status];
    }
}

const StatusIndicatorSmall = (props: any) => {
    const { reportType, status, from, label, minW } = props;
    const s: number = +status;
    const [t] = useTranslation();
    return(
        <React.Fragment>
            <Box sx={{display: 'flex', minWidth: (minW != undefined) ? minW : 90}}>
                {(label != '' && label != undefined) && 
                <Typography sx={{ fontSize: '12px', m: 'auto', mr: '10px' }}
                    className={from == "overview" ? "uppercase" : ""}>{
                        label == 'generate' ? t(displayStatus(reportType, s).statusName) : label
                        }</Typography>}
                <Avatar sx={{
                    width: 10,
                    height: 10,
                    mr: 1,
                    mt: 'auto',
                    mb: 'auto',
                    bgcolor: displayStatus(reportType, s)?.color,
                    border: displayStatus(reportType, s)?.color == '#FFF' ?
                    '1px solid black' : 
                    `1px solid ${displayStatus(reportType, s)?.color}`}}>
                        {' '}
                </Avatar>
                {(label == '' || label == undefined) && t(displayStatus(reportType, s)?.statusName)}
            </Box>
        </React.Fragment>
    )
}

export default StatusIndicatorSmall;